import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink, Observable } from '@apollo/client';

//import { logout } from '../src/utils/auth'
import { getAccessToken, getURL, setAccessToken } from './AccessToken';

//import { from  } from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';

import 'bootstrap/dist/css/bootstrap.min.css';
import { onError } from "@apollo/client/link/error";
//import { onError } from 'apollo-link-error';
//import { ApolloLink, Observable } from 'apollo-link';

//const httpLink = new HttpLink({ uri: 'http://localhost:5000/graphql', credentials: 'include' });

const cache = new InMemoryCache({});


const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(operation =>  {
        //OBTIENE EL TOKEN GUARDADO EN UNA VARIABLE AL LOGUEARSE
      const accessToken = getAccessToken();
      //console.log("EL TOKEN EN REACT INDEX ApolloLink: " + accessToken);
        if (accessToken) {
          //console.log("EL TOKEN EN REACT INDEX 2: " + appJWTToken);
          operation.setContext({
            headers: {
              //Pasamos el token en el header para poder acceder a contenido protegido
              authorization: `bearer ${accessToken}`
            }
          });
        }
      })
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const refreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();
    //console.log("ACCESS TOKEN EN /////////TokenRefreshLink//////// " + token);
    if (!token) {
      console.log("NO TOKEN")
      return true;
    }

    try {
      const {exp} = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        return false; 
      }
      else {
        return true;
      }
    }
    catch {
      console.log("error al refrescar el token 1");
      return false;
    }
  },
  fetchAccessToken: () => {
    const url = getURL()+'/refresh_token';
    return fetch(url, {
      method: 'POST',
      credentials: 'include', // Don't forget to specify this if you need cookies

    });
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken)
  },
  handleError: err => {
     // full control over handling token fetch Error
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([
    refreshLink,
    onError(({ graphQLErrors, networkError }) => {
      console.log("GraphQL error " + JSON.stringify(graphQLErrors));
      console.log("Network  error" + JSON.stringify(networkError));
    }),
    requestLink,
    new HttpLink({
      uri: getURL() + '/graphql',
      //uri: "http://localhost:5000/graphql",
      credentials: 'include'
    })
  ]),
  cache,

  
});

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
  <ApolloProvider client={client} >
    <App />
</ApolloProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
