import React, { useEffect, useRef, useMemo , useState, useContext } from 'react';
import UserContext from '../componets/userContext';
import {Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Badge, Label } from 'reactstrap';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

//import TableContainer from './TableContainer_sinPag';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button  } from '@mui/material';
//import MaterialReactTable from 'material-react-table';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import * as Icon from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import BuscarPersonaPage from './Page_buscarPersona';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const csvConfig = mkConfig({
  filename: 'Inscripciones',
  fieldSeparator: ';',
  decimalSeparator: '.',
  //showColumnHeaders:	true,
  //useKeysAsHeaders: true,
  columnHeaders: [{key: "periodo_academico", displayLabel: "periodo_academico"}, {key: "nombre_carrera", displayLabel: "nombre_carrera"}, {key: "nombre_curso", displayLabel: "nombre_curso"}, {key: "pk_detallealumnosinscriptos", displayLabel: "pk_detallealumnosinscriptos"}, {key: "AsignaturaDescrip", displayLabel: "AsignaturaDescrip"}]
});

const Aed_Matriculaciones = () => {
    const usuario = useContext(UserContext)  
    const persona = usuario.persona.pk_persona;
    let nomApel = '';
    nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;

    const [datos, setDatos] = useState([]);
    const [MensajeError, setMensajeError] = useState("");
    
    
    const [aniolectivotext, setAniolectivotext] = useState("AÃ±o lectivo");
    const [datosAl, setDatosAl] = useState([]);
    const [aniolectivo, setAniolectivo] = useState(0);

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    let tipopersonaBuscador = 2;
    let BotonBuscarPersonaDisable = false;
    const [cargarGrilla, setcargarGrilla] = useState(false);
    const [showBuscarPersona, setshowBuscarPersona] = useState(false);
    const [disablebtnPersona, setDisablebtnPersona] = React.useState(BotonBuscarPersonaDisable);
    
    const [idpersona, setidpersona] = useState('');
    const [nombreyapellido, setnombreyapellido] = useState("");
    const [idpersonaSeleccionada, setidpersonaSeleccionada] = useState('');
    const [nombreyapellidoSeleccionada, setnombreyapellidoSeleccionada] = useState("");

    const [total, setTotal] = useState("0");

    const [valueComp, setValueComp] = React.useState("");
    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const toDay= new Date().toISOString().substring(0, 10);

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(datos);
      download(csvConfig)(csv);
    };

    const QueryDcc = gql`query detallealumnosinscriptosRUEporPersona($persona: Int!) {
      detallealumnosinscriptosRUEporPersona(persona: $persona) {
        periodo_academico
        carrera_id_UDS
        nombre_carrera
        curso_id
        nombre_curso
        pk_detallealumnosinscriptos
        pk_asignatura
        AsignaturaDescrip
      }
    }`;

    const [BuscarDatosGrilla, { loading, error, data }] = useLazyQuery(
        QueryDcc,
          { variables: { persona: idpersona},
          fetchPolicy: "network-only", }
    );

    function ObtenerDatosAnioLectivo ()  {
      const Query = gql` query obtenerDatos($institucion: Int) { 
        aniolectivoPorInstitucion(institucion: $institucion){
          AnioLectivo
      }}`;
          
      const { loading, error, data } = useQuery(Query,
        {variables: { institucion: 3}, fetchPolicy: "network-only" });
          useEffect(() => {
          if(data) {
            const datos1 = data.aniolectivoPorInstitucion;
            //console.log(datos1);
            setDatosAl(datos1);
          }
        }, [data]);
      
        if (loading) return 'Loading...';
          
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
      }
    } 
      
    ObtenerDatosAnioLectivo();

    async function cerrarBuscarPersona() {
      //console.log("LLEGO CERRAR BUSCAR PERSONA")
      setMensajeError("");
      setidpersona(idpersonaSeleccionada);
      setnombreyapellido(nombreyapellidoSeleccionada);
      //loadCursado();
      setshowBuscarPersona(false);
      
    }
    // const totalInscriptos = useMemo(
    //   () => datos.reduce((acc, curr) => acc + curr.cantidadInscriptos, 0),
    //   [datos],
    // );

    const MostrarComboAnioLectivo = () => {
      const handleClickInsti = (e) => {
            setMensajeError("");
            setAniolectivotext(e.target.innerText);
            //console.log(e.target.value)
            var wal =  parseInt(e.target.value);
            if (wal) {
              setAniolectivo(wal);
              //ObtenerDatosAnioLectivo();
            }  
            BuscarDatosGrilla();
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
            <DropdownToggle caret>
              {aniolectivotext}
            </DropdownToggle>          
            <DropdownMenu>
              {datosAl.map(item => (
                  <DropdownItem 
                    key={item.AnioLectivo}
                    value={item.AnioLectivo}
                    onClick={handleClickInsti}>
                    {item.AnioLectivo}
                  </DropdownItem>
              ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }

    const MostrarBotonBuscarPersona = () => {
      const handleClick = (e) => {
          setcargarGrilla(false);
          setshowBuscarPersona(true);
      }
      return (
        <Button color="primary" onClick={handleClick} disabled={disablebtnPersona}><Icon.Search /></Button>
      )
    }

  const theme = useTheme(); //replace with your theme/createTheme
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  const columns = useMemo(() => {
    
    var arrayColumnas =  [
      {
        header: 'periodo_academico',
        accessorKey: 'periodo_academico',
      },
      
      {
        header: 'nombre_carrera',
        accessorKey: 'nombre_carrera',
      },
      
      {
        header: 'nombre_curso',
        accessorKey: 'nombre_curso',
      },
      {
        header: 'pk_detallealumnosinscriptos',
        accessorKey: 'pk_detallealumnosinscriptos',
      },
      
      {
        header: 'AsignaturaDescrip',
        accessorKey: 'AsignaturaDescrip',
      },
    ]

    return arrayColumnas}, [datos]
  );

  const table = useMaterialReactTable({
    id: "table-to-xls",
    columns,
    data: datos,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        enableResizing: true,
      },
    },
    //enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableGlobalFilter: false,
 
    initialState: {
      density: 'compact',
      expanded: false, //expand all groups by default
      //grouping: ['carrera', 'curso'], //an array of columns to group by by default (can be multiple)
      columnVisibility: { tipocarrera: false, convocatoria: false }
      //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
    },
       
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 640 } },
    localization: MRT_Localization_ES,    

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          
        </Button>
        
      </Box>
    ),


  });
  
  useEffect(() => {
    if(idpersona!=='') {
      console.log("carga Grilla");
      BuscarDatosGrilla();
    }
  }, [idpersona]);

      //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
      if(data) {
        console.log(data);
        const datosUsu = data.usuarioLogueado
        //set 
        setDatos(data.detallealumnosinscriptosRUEporPersona);
      }
  }, [data]);

  if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  }
    
  return (
    <div >
      <Form>

          <FormGroup row>
                          <Col sm={2} className='mt-1'>
                            <Input type="text" name="idpersona" id="idpersona" placeholder="Id" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={idpersona} required={true} />
                          </Col>
                          <Col sm={8} className='mt-1'>
                            <Input type="text" name="nombrepersona" id="nombrepersona" placeholder="Nombre y apellido" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={nombreyapellido} required={true} />
                          </Col>
                          <Col sm={2} className='mt-1'>
                            {MostrarBotonBuscarPersona()}
                          </Col>
                        </FormGroup>
        </Form>

        <MaterialReactTable
            table={table} //only pass in table instead of all table options
        />

        <Modal show={showBuscarPersona} onHide={cerrarBuscarPersona} size="lg">
              <Modal.Header>
                <Modal.Title>Busqueda de persona</Modal.Title>
                </Modal.Header>
              <Modal.Body>
                  <BuscarPersonaPage posicionarTipopersona = {tipopersonaBuscador} setcargarGrilla = {setcargarGrilla} setshowBuscarPersona = {setshowBuscarPersona} setidpersona = {setidpersona} setnombreyapellido={setnombreyapellido} setidpersonaSeleccionada = {setidpersonaSeleccionada} setnombreyapellidoSeleccionada = {setnombreyapellidoSeleccionada}/>
              </Modal.Body>
                <Modal.Footer>
                  
                  <Button color="primary" onClick={cerrarBuscarPersona}>
                    Aceptar
                  </Button>
              </Modal.Footer>
          </Modal>
    </div >
    )
};

export default Aed_Matriculaciones;