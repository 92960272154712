import React, { useEffect, useRef, useMemo , useState, useContext } from 'react';
import UserContext from '../../componets/userContext';
import {Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Badge, Label } from 'reactstrap';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

//import TableContainer from './TableContainer_sinPag';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button  } from '@mui/material';
//import MaterialReactTable from 'material-react-table';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const csvConfig = mkConfig({
  filename: 'Inscripciones',
  fieldSeparator: ';',
  decimalSeparator: '.',
  //showColumnHeaders:	true,
  //useKeysAsHeaders: true,
  columnHeaders: [{key: "tipocarrera", displayLabel: "Tipo"}, {key: "convocatoria", displayLabel: "Convocatoria"}, {key: "carrera", displayLabel: "Carrera"}, {key: "curso", displayLabel: "AÃ±o"}, {key: "alumno", displayLabel: "Alumno"}, {key: "cantidadInscriptos", displayLabel: "Inscriptos"}]
});

const Aed_Matriculaciones = () => {
    const usuario = useContext(UserContext)  
    const persona = usuario.persona.pk_persona;
    let nomApel = '';
    nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;

    const [datos, setDatos] = useState([]);
    const [MensajeError, setMensajeError] = useState("");
    
    
    const [aniolectivotext, setAniolectivotext] = useState("AÃ±o lectivo");
    const [datosAl, setDatosAl] = useState([]);
    const [aniolectivo, setAniolectivo] = useState(0);

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [estadotext, setEstadotext] = useState("Matriculado");
    const [estado, setEstado] = useState(1);
    const [dropdownOpenEstado, setOpenEstado] = useState(false);
    const toggleEstado = () => setOpenEstado(!dropdownOpenEstado);
    const [datosEstado, setDatosEstado] = useState([]);

    const [total, setTotal] = useState("0");

    const [valueComp, setValueComp] = React.useState("");
    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const toDay= new Date().toISOString().substring(0, 10);

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(datos);
      download(csvConfig)(csv);
    };

    const QueryDcc = gql`query Aed_Matriculados_por_anio_carrera($aniomalla: Int, $estadoacademico: String) {
      Aed_Matriculados_por_anio_carrera(aniomalla: $aniomalla estadoacademico: $estadoacademico) {
        anho
        carrera
        curso
        alumno
        cantidadInscriptos
        convocatoria
        tipocarrera
        sucursal
      }
    }`;

    const [cargarGrilla, { loading, error, data }] = useLazyQuery(
        QueryDcc,
          { variables: { aniomalla: aniolectivo, estadoacademico: estadotext},
          fetchPolicy: "network-only", }
    );

    function ObtenerDatosAnioLectivo ()  {
      const Query = gql` query obtenerDatos { 
        Aed_allanhomalla{
          anho
      }}`;
          
      const { loading, error, data } = useQuery(Query,
        {fetchPolicy: "network-only" });
          useEffect(() => {
          if(data) {
            const datos1 = data.Aed_allanhomalla;
            //console.log(datos1);
            setDatosAl(datos1);
          }
        }, [data]);
      
        if (loading) return 'Loading...';
          
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
      }
    } 
      
    ObtenerDatosAnioLectivo();

    const totalInscriptos = useMemo(
      () => datos.reduce((acc, curr) => acc + curr.cantidadInscriptos, 0),
      [datos],
    );

    const MostrarComboAnioLectivo = () => {
      const handleClickInsti = (e) => {
            setMensajeError("");
            setAniolectivotext(e.target.innerText);
            //console.log(e.target.value)
            var wal =  parseInt(e.target.value);
            if (wal) {
              setAniolectivo(wal);
              //ObtenerDatosAnioLectivo();
            }  
            cargarGrilla();
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
            <DropdownToggle caret>
              {aniolectivotext}
            </DropdownToggle>          
            <DropdownMenu>
              {datosAl.map(item => (
                  <DropdownItem 
                    key={item.anho}
                    value={item.anho}
                    onClick={handleClickInsti}>
                    {item.anho}
                  </DropdownItem>
              ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }

    function ObtenerDatosEstado ()  {
      const Query = gql` query obtenerDatos { 
        Aed_MatriculacionEstados{
          estadoacademico
      }}`;
          
      const { loading, error, data } = useQuery(Query,
        {fetchPolicy: "network-only" });
          useEffect(() => {
          if(data) {
            const datos1 = data.Aed_MatriculacionEstados;
            //console.log(datos1);
            setDatosEstado(datos1);
          }
        }, [data]);
      
        if (loading) return 'Loading...';
          
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
      }
    } 

    ObtenerDatosEstado();

    const MostrarComboEstado = () => {
      const handleClick = (e) => {
            setMensajeError("");
            setEstadotext(e.target.innerText);
            //console.log(e.target.value)
            // var wal =  parseInt(e.target.value);
            // if (wal) {
            //   setEstado(wal);
            //   //ObtenerDatosAnioLectivo();
            // }  
            
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpenEstado} toggle={toggleEstado} className="my-dropdown" >
            <DropdownToggle caret>
              {estadotext}
            </DropdownToggle>          
            <DropdownMenu>
              <DropdownItem value={1}
                  onClick={handleClick}>
                  Todos
              </DropdownItem>
              {datosEstado.map(item => (
                  <DropdownItem 
                    key={item.estadoacademico}
                    value={item.estadoacademico}
                    onClick={handleClick}>
                    {item.estadoacademico}
                  </DropdownItem>
              ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }

  const theme = useTheme(); //replace with your theme/createTheme
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  const columns = useMemo(() => {
    
    var arrayColumnas =  [
      {
        header: 'Sucursal',
        accessorKey: 'sucursal',
      },
      {
        header: 'Tipo',
        accessorKey: 'tipocarrera',
      },
      {
        header: 'Convocatoria',
        accessorKey: 'convocatoria',
      },
      {
        accessorKey: 'carrera',
        header: 'Carrera',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
        //Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{cell.getValue()}</div>,
      },
      
      {
        header: 'AÃ±o',
        accessorKey: 'curso',
        //enableColumnActions: false,
        //accessorFn: (row) => row?.curso.Aranceles?.Descripcion, //Muestra solo si tiene el dato
        Cell: ({ cell }) => <Box sx={{  textAlign: "center" }}><div>AÃ±o {cell.getValue()}</div></Box>,
        enableEditing: false, //disable editing on this column
        Footer: () => (
          <FormGroup> 
            <Col style={{paddingBottom: '4px' }}>
              <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold'}} disabled 
                value="Total">
              </Input>  
            </Col>
            
          </FormGroup>
        ),
      },
      {
        header: 'Alumno',
        accessorKey: 'alumno',
      },
      {
        header: 'Inscriptos',
        accessorKey: 'cantidadInscriptos',
        //accessorFn: (row) => `${row.Ctacte.Pensumcarrera.Carrera.Siglas} ${row.Ctacte.Pensumcarrera.Aniopensum}`, //accessorFn used to join multiple data into a single cell
        //accessorFn: (row) => <div style={{ textAlign: "center" }}>{row.cantidadInscriptos}</div>, //Muestra solo si tiene el dato
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
        //disableFilters: true
        enableColumnActions: false,
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => <Box sx={{  textAlign: "center", fontWeight: 'bold' }}>{cell.getValue()}</Box>,
        //customize normal cell render on normal non-aggregated rows
        Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{cell.getValue()}</div>,
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={totalInscriptos}>
              </Input>
            </Col>
          </FormGroup>
        ),
      },
    ]

    return arrayColumnas}, [datos, totalInscriptos]
  );

  const table = useMaterialReactTable({
    id: "table-to-xls",
    columns,
    data: datos,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        enableResizing: true,
      },
    },
    //enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableGlobalFilter: false,
 
    initialState: {
      density: 'compact',
      expanded: false, //expand all groups by default
      grouping: ['carrera', 'curso'], //an array of columns to group by by default (can be multiple)
      columnVisibility: { tipocarrera: false, convocatoria: false }
      //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
    },
       
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 640 } },
    localization: MRT_Localization_ES,    

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          
        </Button>
        
      </Box>
    ),


  });
  
      //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
      if(data) {
        //console.log(data);
        const datosUsu = data.usuarioLogueado
        //set 
        setDatos(data.Aed_Matriculados_por_anio_carrera);
      }
  }, [data]);

  if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  }
    
  return (
    <div >
      <Form>

          <FormGroup row>
            
            <Col sm={2} className='mt-1'>
              {MostrarComboAnioLectivo()}
            </Col>
            <Col sm={1} className='mt-1'>{MostrarComboEstado()}</Col>
        

          </FormGroup>
        </Form>

          <MaterialReactTable
            table={table} //only pass in table instead of all table options
            
          />


    </div >
    )
};

export default Aed_Matriculaciones;