import React from 'react';
import Container from 'react-bootstrap/Container';

const Layout = (props) => (
    <div className="container-fluid p-1 my-1">
        <Container fluid>
            {props.children}
        </Container>
    </div>
)

export default Layout;