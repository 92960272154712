import React from 'react';
import { gql, useMutation } from '@apollo/client';

//import gql from 'graphql-tag';
//import {useMutation} from 'react-apollo';
import {Alert } from 'reactstrap';
import {getURLReportes} from '../AccessToken';

function LogoutPage() {
    //const history = useHistory();
    const Query = gql` mutation {
        logoutUds
    }`;
      
    const Desloguearse = () => {
        
        const { loading, error, data } = useMutation(Query);

        if (loading) return 'Loading...';
        
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
        }
        console.log(data);

        fetch(getURLReportes() +`/reportes/logout.jsp`, {
                method: "GET",
                mode: 'no-cors',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(resDataF => {
                console.log(JSON.stringify(resDataF));
            })
            .catch(errF => {
                console.log(errF);
            }) 
            
        return (
            <div>"Log"</div>
           /*  <Redirect to='/login'/>  */
        )
    } 

    /* const  { called, data, loading, error } = useMutation(Query,
    {
      onCompleted: ({ logoutUds }) => {
        window.alert(`received ${logoutUds.value}`);
        return (
            <Redirect to='/login'/> 
        )
      }},);

    function Desloguearse() {
        const [desloguearse, { called, data, loading, error }] = useMutation(Query);
        return desloguearse;
    } */

    //const x = Desloguearse();

    //client.resetStore().then(() => console.log("logged out!"));
    
    
    return (
        <div><Desloguearse></Desloguearse></div>
        
    )
}
export default LogoutPage;

