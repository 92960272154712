import React, { useEffect, useState, useContext } from "react";
import TableContainer from './TableContainer_sinPag';
import { SelectColumnFilter } from './filters';
//import Button from 'react-bootstrap/Button'
import {Button, Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Alert } from 'reactstrap';
import { LinkContainer } from "react-router-bootstrap";
import Nav from 'react-bootstrap/Nav';
//import { getAccessToken, getURL} from '../AccessToken';
//import gql from 'graphql-tag';
//import {useQuery} from 'react-apollo';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
//import { useLazyQuery } from 'react-apollo';
import UserContext from '../componets/userContext';
//import { relative } from "path";
import { DefaultColumnFilter } from './filters';

import {
  Link
} from "react-router-dom"

//import ListaAsignaturas from '../componets/ListaAsignaturas';
import notas_page from './notas_page';
//import { useHistory } from "react-router-dom";

function Notas_grilla(props)  {
  //const { state } = props.location
  const [aniolectivotext, setAniolectivotext] = useState("AÃ±o lectivo");
  const [datosAl, setDatosAl] = useState([]);
  const [aniolectivo, setAniolectivo] = useState();
  
  //if (state) {
    //console.log(state.aniolectivoPos);
  //}
  
  //const wpersona =15;
  //console.log(wpersona);
  //const history = useHistory();
  const usuario = useContext(UserContext)  
  const persona = usuario.persona.pk_persona;
  //console.log("USER EN CONTEXT " + JSON.stringify(usuario));
  //const serverurl = getURL()+'/graphql';

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const [datos, setDatos] = useState([]);

  //const [originalData, setoriginalData] = useState([]);
  // declaramos la bandera de ediciÃ³n
  //const [editable, setEditable] = useState(false);
  const editable= false;
  //const [usuario, setUsuario] = useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const QueryAsig = gql`query obtenerdatos($persona: Int!, $aniolectivo: Int!) {
    detalleCursoDocenteAniolec(persona:$persona aniolectivo:$aniolectivo) {
      Pk_DetalleCurso
      AulaCompartida
      Curso {
        Pk_Curso
        Institucion{
          Pk_Institucion
          Siglas
        }
        AnioLectivo
        DetallePeriodo {
          Pk_detalleperiodo
          Periodo
          Ciclo{
            Descripcion
          }
        }
        Anio{
          Pk_Anio
          Descripcion
        }
        Horario{
          Pk_Horario
          Descripcion
        }
        Seccion{
          Pk_Seccion
          Descripcion
        }
      }
      Asignatura {
        Pk_Asignatura
        Descripcion  
        EsTesis
      }
      Persona {
        pk_persona 
        Nombre
        Apellido
      }
    }
  }`;
  
  //console.log(usuario.Persona);
  const [loadAsignaturas, { loading, error, data }] = useLazyQuery(
    QueryAsig,
    { variables: { persona: persona,
      aniolectivo: aniolectivo, }, 
      fetchPolicy: "network-only", }
  );
  
  function ObtenerDatosAnioLectivo ()  {
    const Query = gql` query obtenerDatosAL($persona: Int!) {
      aniolectivoPorDocente(persona: $persona) {
        AnioLectivo
      }
    }`;
    
    const { loading, error, data } = useQuery(Query,
      { variables: { 
        persona: persona,
       }, fetchPolicy: "network-only" },);

    useEffect(() => {
        if(data) {
          const datos1 = data.aniolectivoPorDocente;
          setDatosAl(datos1)
        }
    }, [data]);

    if (loading) return <Alert color="info">
          Cargando...
        </Alert>;
    
    if (error) {
         console.log(error.message)
         return (
           <Alert color="danger">
             {error.message}
           </Alert>
         )
    }
  } 

  ObtenerDatosAnioLectivo();

   /* useEffect(() => {
    //con el await forzamos a que espere el resultado para buscar el aÃ±o lectivo
    //const datosUsu = await ObtenerUsuario();
    //console.log(datosUsu.Persona);
    async function fetchData() {

      const ObtenerDatosAnioLec = async  (wpersona) => {
        //console.log("Obtener aÃ±o lectivo ");
        
        const QueryAnioLectivo = {
          query: `
          query obtenerDatosAL($persona: Int!) {
            aniolectivoPorDocente(persona: $persona) {
              AnioLectivo
            }
          }
          `,
          variables: {
            persona: wpersona,
          },
        };
        //console.log(getAccessToken());
        const response = await  fetch(serverurl, {
            method: 'POST',
            body: JSON.stringify(QueryAnioLectivo),
            credentials: 'include',
            //cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + getAccessToken()
                'Authorization': 'accessToken: ' + getAccessToken() 
            }  
          },
        )
        if (response.ok) { // if HTTP-status is 200-299
          const body = await response.json();
          //console.log("TIK" + JSON.stringify(body));
          const datos1 = await body.data.aniolectivoPorDocente;
          setDatosAl(datos1)
        } else {
          alert("HTTP-Error: " + response.status);
        }
      } 

      if (usuario.Persona) {
        ObtenerDatosAnioLec(usuario.Persona)
      }
    }
    fetchData();
  }, [usuario, serverurl]);  */
 
  const MostrarCombo = () => {
/*     const Query = gql` query obtenerDatosAL($persona: Int!) {
      aniolectivoPorDocente(persona: $persona) {
        AnioLectivo
      }
    }`;
    
    const { loading, error, data } = useQuery(Query,
      { variables: { 
        persona: usuario.Persona,
       }, fetchPolicy: "network-only" },);

    if (loading) return 'Loading...';
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
    const datosAL = data.aniolectivoPorDocente; */
    //console.log(JSON.stringify(data));
    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color="primary">
            {aniolectivotext}
          </DropdownToggle>          
          <DropdownMenu>
              {datosAl.map(item => (
                  <DropdownItem
                      key={item.AnioLectivo}
                      value={item.AnioLectivo}
                      onClick={handleClick}>
                    {item.AnioLectivo}
                  </DropdownItem>
              ))}
          </DropdownMenu>
       </ButtonDropdown>
    )
  }

  const AsignaturaCell = ({
      value: initialValue,
      row: { original  },
      column: { id },
    }) => {
      //const [value, setValue] = React.useState(initialValue)
      //const [value] = React.useState(initialValue);
      //const [value, setValue] = React.useState(initialValue);
      return (
        //<Button onClick={Mensaje}>{value}</Button>
        <LinkContainer style={{ height: '1vw', display:'flex', alignItems:'center', color:'#007BFF' }} to={{pathname: './notas_page', state: { 
          aulacompartida: original.AulaCompartida, 
          institucion: original.Curso.Institucion.Pk_Institucion,
          aniolectivo: original.Curso.AnioLectivo,
          detalleperiodo: original.Curso.DetallePeriodo.Pk_detalleperiodo,
          horario: original.Curso.Horario.Pk_Horario,
          seccion: original.Curso.Seccion.Pk_Seccion,
          asignatura: original.Asignatura.Pk_Asignatura,
          curso: original.Curso.Pk_Curso,
          persona: original.Persona.pk_persona, 
          asigdescrip: original.Asignatura.Descripcion, 
          EsTesis: original.Asignatura.EsTesis, 
          },
          }}><Nav.Link >{original.Asignatura.Descripcion}</Nav.Link></LinkContainer>
      )       
      //<a href="#" onClick={Mensaje}>{value}</a>;
  }
    
  const columns = React.useMemo(
      () => [
        {
          Header: 'InstituciÃ³n',
          accessor: 'Curso.Institucion.Siglas', // accessor is the "key" in the data
          Filter: SelectColumnFilter,
          filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
         
        },
        {
          Header: 'Ciclo',
          accessor: 'Curso.DetallePeriodo.Ciclo.Descripcion',
          Filter: SelectColumnFilter,
          filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
          //disableFilters: true
        },
        {
          Header: 'AÃ±o',
          accessor: 'Curso.Anio.Descripcion',
          Filter: SelectColumnFilter,
          filter: 'equals'
          //disableFilters: true
        },
        {
          Header: 'Turno',
          accessor: 'Curso.Horario.Descripcion',
          Filter: SelectColumnFilter,
          filter: 'equals'
        },
        {
          Header: 'Seccion',
          accessor: 'Curso.Seccion.Descripcion',
          Filter: SelectColumnFilter,
          filter: 'equals'
        },
        {
          Header: 'Asignatura',
          accessor: 'Asignatura.Descripcion',
          Filter: DefaultColumnFilter,
          Cell: AsignaturaCell
        },
        /*{
          Header: 'pkAsignatura',
          accessor: 'Asignatura.Pk_Asignatura',
          
        },
          {
          Header: 'EsTesis',
          accessor: 'Asignatura.EsTesis',
          Filter: SelectColumnFilter,
          filter: 'equals'
        },*/
      ],
      []
  );

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
  const updateMyData = (rowIndex, columnId, value, columns) => {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true)
      setDatos(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            //console.log(columns)
            //console.log(columnId)
            //  if (columnId === 'Hemisphere'){
            //   if (isNaN(value)) {
            //     return {
            //       ...old[rowIndex],
            //       [columnId]: value+"prueba",
            //     }
            //   }
            //  }
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
  }

  const handleClick = (e) => {
      //console.log(e.target.value);
      var waniolectivo =  parseInt(e.target.value);
      if (waniolectivo) {
        setAniolectivotext(e.target.value);
        setAniolectivo(waniolectivo)
        loadAsignaturas();
      }
  } 

  //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
      if(data) {
        //const datosUsu = data.usuarioLogueado
        //set 
        setDatos(data.detalleCursoDocenteAniolec);
        //console.log(data);
      }
    }, [data]);

    if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  }

  function Volver()  {
    //history.goBack();

    // navigate({
    //   pathname: '/notas',
    //     state: {aniolectivoPos: waniolectivo} // your data array of objects
    // });
  }
  //setDatos(data.detalleCursoDocenteAniolec);  
  return (
      <Container>
        <Form>
          <FormGroup>
            {MostrarCombo ()}
            &nbsp;
            <Button color="info" onClick={Volver}>
                        Volver
                      </Button>
    
          </FormGroup>
        </Form>
        <TableContainer 
          columns={columns} 
          data={datos}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          editable={editable}
        />
      </Container>
  )
}

export default Notas_grilla;