import React, { useEffect, useRef, useMemo , useState, useContext, forwardRef  } from 'react';
import UserContext from '../../componets/userContext';
import { Button, Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Badge, Label } from 'reactstrap';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

//import TableContainer from './TableContainer_sinPag';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, button, Card   } from '@mui/material';
//import MaterialReactTable from 'material-react-table';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import moment from 'moment';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

import "./styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { es } from 'date-fns/locale/es';
import { startTransition } from 'react';
registerLocale('es', es)

const customStyles = {
  content: {
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const csvConfig = mkConfig({
  filename: 'Pagos',
  fieldSeparator: ';',
  decimalSeparator: '.',
  //showColumnHeaders:	true,
  //useKeysAsHeaders: true,
  columnHeaders: [{key: "sucursal", displayLabel: "Sucursal"}, {key: "ArancelDescrip", displayLabel: "Arancel"}, {key: "fecha", displayLabel: "Fecha"}, {key: "fechaFacturacion", displayLabel: "Fecha fact."}, {key: "nrofactura", displayLabel: "Nro. Factura"}, {key: "tipopago", displayLabel: "Tipo Pago"}, {key: "total", displayLabel: "Monto"}]
});

const Aed_Pagos = () => {
    const usuario = useContext(UserContext)  
    //const persona = usuario.persona.pk_persona;
    let nomApel = '';
    nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;

    const [datos, setDatos] = useState([]);
    const [MensajeError, setMensajeError] = useState("");
    
    const [facturaciontext, setFacturaciontext] = useState("Facturado");
    const [facturacion, setfacturacion] = useState(1);
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);


    const [estadotext, setEstadotext] = useState("Activo");
    const [estado, setEstado] = useState(1);
    const [dropdownOpenEstado, setOpenEstado] = useState(false);
    const toggleEstado = () => setOpenEstado(!dropdownOpenEstado);
    const [datosEstado, setDatosEstado] = useState([]);

    //const toDay= new Date().toISOString().substring(0, 10);
    //let currentDate = new Date();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(datos);
      download(csvConfig)(csv);
    };

    const QueryDcc = gql`query Aed_Cobros($fechadesde: String, $estado: String, $fechahasta: String, $facturado: Int) {
      Aed_Cobros(fechadesde: $fechadesde estado:$estado fechahasta: $fechahasta facturado: $facturado) {
        idcobranzas
        nrofactura
        estado
        fecha
        fechaFacturacion
        monto
        descuento
        total
        ArancelDescrip
        descripcion
        tipopago
        sucursal
      }
    }`;

    const [cargarGrilla, { loading, error, data }] = useLazyQuery(
        QueryDcc,
          { variables: { fechadesde: fechaInicio, estado:estadotext, fechahasta: fechaFin, facturado: facturacion},
          fetchPolicy: "network-only", }
    );

     
    //ObtenerDatosAnioLectivo();
    const totalPrecio = useMemo(
      () => datos.reduce((acc, curr) => acc + curr.monto, 0),
      [datos],
    );
    const totalDescuento = useMemo(
      () => datos.reduce((acc, curr) => acc + curr.descuento, 0),
      [datos],
    );

    const totalMontoFinal = useMemo(
      () => datos.reduce((acc, curr) => acc + curr.total, 0),
      [datos],
    );

    const MostrarComboFacturacion = () => {
      const handleClickFac = (e) => {
            setMensajeError("");
            setFacturaciontext(e.target.innerText);
            //console.log(e.target.value)
            var wal =  parseInt(e.target.value);
            if (wal) {
              setfacturacion(wal);
              console.log(wal);
              //ObtenerDatosAnioLectivo();
            }  
            //cargarGrilla();
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
            <DropdownToggle caret>
              {facturaciontext}
            </DropdownToggle>          
            <DropdownMenu>
              
                  <DropdownItem value={1}
                    onClick={handleClickFac}>
                    Facturado
                  </DropdownItem>
                  <DropdownItem value={2}
                    onClick={handleClickFac}>
                    Pendiente
                  </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
      )
    }

    function ObtenerDatosEstado ()  {
      const Query = gql` query obtenerDatos { 
        Aed_CobrosEstados{
          estado
      }}`;
          
      const { loading, error, data } = useQuery(Query,
        {fetchPolicy: "network-only" });
          useEffect(() => {
          if(data) {
            const datos1 = data.Aed_CobrosEstados;
            //console.log(datos1);
            setDatosEstado(datos1);
          }
        }, [data]);
      
        if (loading) return 'Loading...';
          
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
      }
    } 
      
    ObtenerDatosEstado();

    const MostrarComboEstado = () => {
      const handleClick = (e) => {
            setMensajeError("");
            setEstadotext(e.target.innerText);
            //console.log(e.target.value)
            // var wal =  parseInt(e.target.value);
            // if (wal) {
            //   setEstado(wal);
            //   //ObtenerDatosAnioLectivo();
            // }  
            
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpenEstado} toggle={toggleEstado} className="my-dropdown" >
            <DropdownToggle caret>
              {estadotext}
            </DropdownToggle>          
            <DropdownMenu>
              <DropdownItem value={1}
                  onClick={handleClick}>
                  Todos
              </DropdownItem>
              {datosEstado.map(item => (
                  <DropdownItem 
                    key={item.estado}
                    value={item.estado}
                    onClick={handleClick}>
                    {item.estado}
                  </DropdownItem>
              ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }


  const theme = useTheme(); //replace with your theme/createTheme
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  const columns = useMemo(() => {
    
    var arrayColumnas =  [
      {
        header: 'Sucursal',
        accessorKey: 'sucursal',
      },
      {
        header: 'Arancel',
        accessorKey: 'ArancelDescrip',
      },
      {
        header: 'Fecha',
        accessorKey: 'fecha',
      },
      {
        header: 'Estado',
        accessorKey: 'estado',
      },
      {
        header: 'Fecha fact',
        accessorKey: 'fechaFacturacion',
      },
      {
        header: 'Nro. Factura',
        accessorKey: 'nrofactura',
      },
      {
        header: 'Tipo Pago',
        accessorKey: 'tipopago',
      },
      {
        header: 'Precio',
        accessorKey: 'monto',
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
        //disableFilters: true
        enableColumnActions: false,
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => <Box sx={{  textAlign: "center", fontWeight: 'bold' }}>
          {cell.getValue()?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}
              </Box>,
        //customize normal cell render on normal non-aggregated rows
        Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{new Intl.NumberFormat("es-PY", {maximumFractionDigits: 2}).format(cell.getValue())}</div>,
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={totalPrecio?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}>
              </Input>
            </Col>
          </FormGroup>
        ),
      },
      {
        header: 'Descuento',
        accessorKey: 'descuento',
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
        //disableFilters: true
        enableColumnActions: false,
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => <Box sx={{  textAlign: "center", fontWeight: 'bold' }}>
          {cell.getValue()?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}
              </Box>,
        //customize normal cell render on normal non-aggregated rows
        Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{new Intl.NumberFormat("es-PY", {maximumFractionDigits: 2}).format(cell.getValue())}</div>,
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={totalDescuento?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}>
              </Input>
            </Col>
          </FormGroup>
        ),
      },
      {
        header: 'Monto Final',
        accessorKey: 'total',
        //accessorFn: (row) => `${row.Ctacte.Pensumcarrera.Carrera.Siglas} ${row.Ctacte.Pensumcarrera.Aniopensum}`, //accessorFn used to join multiple data into a single cell
        //accessorFn: (row) => <div style={{ textAlign: "center" }}>{row.cantidadInscriptos}</div>, //Muestra solo si tiene el dato
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
        //disableFilters: true
        enableColumnActions: false,
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => <Box sx={{  textAlign: "center", fontWeight: 'bold' }}>
          {cell.getValue()?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}
              </Box>,
        //customize normal cell render on normal non-aggregated rows
        Cell: ({ cell }) => <div style={{ textAlign: "center" }}>{new Intl.NumberFormat("es-PY", {maximumFractionDigits: 2}).format(cell.getValue())}</div>,
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={totalMontoFinal?.toLocaleString?.('es-PY', {maximumFractionDigits: 2})}>
              </Input>
            </Col>
          </FormGroup>
        ),
      },
    ]

    return arrayColumnas}, [datos, totalMontoFinal]
  );

  const table = useMaterialReactTable({
    id: "table-to-xls",
    columns,
    data: datos,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        enableResizing: true,
      },
    },
    //enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableGlobalFilter: false,
 
    initialState: {
      density: 'compact',
      expanded: false, //expand all groups by default
      grouping: ['ArancelDescrip'], //an array of columns to group by by default (can be multiple)
      columnVisibility: { nrofactura: false, tipopago: false }
      //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
    },
       
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 640 } },
    localization: MRT_Localization_ES,    

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button size="sm"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          //startIcon={<FileDownloadIcon />}
        >
          <FileDownloadIcon />
        </Button >
        
      </Box>
    ),
  });
  
  const onChangeDatePicker = (dates) => {
    const [start, end] = dates;
    //console.log(start);
    setStartDate(start);
    setEndDate(end);

  };

  useEffect(() => {
    //console.log(buscarDatos);
    if(endDate) {
      //currentDate = moment(new Date(startDate)).format("YYYY/MM/DD");
      //console.log(currentDate);
      setFechaInicio(moment(new Date(startDate)).format("YYYY/MM/DD"));
      setFechaFin(moment(new Date(endDate)).format("YYYY/MM/DD"));
      
      cargarGrilla();
    }
  }, [endDate]);


      //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
      if(data) {
        //console.log(data);
        const datosUsu = data.usuarioLogueado
        //set 
        setDatos(data.Aed_Cobros);
        
      }
  }, [data]);

  if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  }
  
  return (
    <div >
      <Form>
          <FormGroup row>
            <Col sm={2} className='mt-1'>{MostrarComboFacturacion()}</Col>
            <Col sm={1} className='mt-1'>{MostrarComboEstado()}</Col>
            <Col sm={2} className='mt-1'>
              
              <DatePicker
                selected={startDate}
                onChange={onChangeDatePicker}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                monthsShown={2}
                //dateFormat="dd/MM/YYYY"
                dateFormat="YYYY/MM/dd"
                //customInput={<ExampleCustomInput />}
                withPortal
                locale="es"
                className="DatePicker"
                strictParsing={true}
              />
            </Col>
            
           
          </FormGroup>
          
        </Form>
       
        <MaterialReactTable
            table={table} //only pass in table instead of all table options
        />
    </div >
    )
};

export default Aed_Pagos;