import React from 'react';

//import NavLink  from 'react-router-dom.NavLink';
//import {NavLink} from 'react-router-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Layout';
//import CrearUser from '../pages/crear_user';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
//import TicketPage from '../pages/tickets';
//import GrillaBasica from '../pages/grillabasica';
import Notas_grilla from '../pages/Notas_grilla';
import Notas_page from '../pages/notas_page';
import Homologaciones_grilla from '../pages/homologaciones_page';
import usuarioAutenticado from '../pages/usuarioAutenticado';


//import Movimiento_Page from '../pages/Page_movimiento';
import Movimiento_Page from '../pages/PageMaterialTablePrueba';
import GestionTitulos_Page from '../pages/gestiontitulos_page';
import Matriculaciones_Aed_Page from '../pages/Aed/Aed_page_matriculaciones';

import Matriculaciones_Page from '../pages/Page_matriculaciones';

import Pagos_Page from '../pages/Aed/Aed_page_pagos';

//import GrillaPage from '../pages/grilla';
//import GrillaPageB from '../pages/grilla_bootstrap';
//import RecFacial from '../pages/recfacial';
//import UserList from '../pages/userlist';
//import Cabecera from './NavLinks copy';
//import Navliks from './NavLinksNotas';
import Cabecera from './Cabecera';
import Sidebar from './MenuLateral';
import OffcanvasExample from './OffcanvasExample';

//import NuevoUser from '../pages/nuevouser';
//import Loguearse from '../pages/loguearse';
import { getAccessToken } from '../AccessToken';
//import UsuariosList from '../pages/usuarioslist';
import { UserProvider } from './userContext';

function SecuredRoute(props) {
    return(
        <Route path={props.path} render={data=>getAccessToken()?(
        <props.component {...data}></props.component>):
        (<Redirect to={{pathname:'/login'}}></Redirect>)}>

        </Route>
    )
}

const DefaultLayout = () => (
    <div>
        <Cabecera />
        <Layout>
            
            <Route path="/notas" component={Notas_grilla} />
            <Route path="/homologaciones" component={Homologaciones_grilla} />
            <Route path="/inscripciones" component={Matriculaciones_Aed_Page} />
            <Route path="/pagos" component={Pagos_Page} />
            <Route path="/movimiento" component={Movimiento_Page} />
            <Route path="/notas_page" component={Notas_page} />
            <Route path="/titulos" component={GestionTitulos_Page} />
            
            <Route path="/matriculaciones" component={Matriculaciones_Page} />

            <Route path="/usuarios" component={usuarioAutenticado} />
            {/* <Route path="/usuario" component={usuarioAutenticado} />
            <Route path="/usuarioEspecifico" component={usuarioEspecifico} />  */}
            
        </Layout>
    </div>
);

const NavigationBar = (props) => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/">
                <Redirect to="/login" />
            </Route>
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
            <UserProvider> 
                <SecuredRoute component={DefaultLayout} />
            </UserProvider>
        </Switch>
    </BrowserRouter>
)

export default NavigationBar;