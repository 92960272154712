import React from 'react';
import {useQuery, gql} from '@apollo/client';
//import gql from 'graphql-tag';
import { Alert } from 'reactstrap';


  const ObtenerUsuario = () => {
    //onst data = ObtenerDatosAL();
    const Query = gql`query {
      usuarioLogueado{
        pk_usuario
        Persona {
          pk_persona
        }
        User
      }
    }`;
    const { loading, error, data } = useQuery(Query, {
      fetchPolicy: "network-only"
      //fetchPolicy:"no-cache"
    });
    
    if (loading) return 'Loading...';
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
    const datos = data.usuarioLogueado
    console.log(JSON.stringify(datos))
    //set persona
    //setPersona(datos.Persona)
    
    return (
        <div>
            USUARIO: {datos.User}
        </div>
    )
}

function usuarioAutenticado() {
    return ( 
        <div className="app">
            {ObtenerUsuario()}          
      </div>
      )
}
export default usuarioAutenticado;