import React, { useEffect, useState, useRef }  from 'react'
import {Container, Button, Form, Row, Col, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert} from 'reactstrap';
//import {useQuery} from 'react-apollo';
//import gql from 'graphql-tag';
import { gql, useQuery } from '@apollo/client';

import { useTable, useSortBy, useFilters } from 'react-table';
import Table from 'react-bootstrap/Table';
import { Filter, DefaultColumnFilter } from './filters';
//import TableContainer from './TableContainer_sinPag';


const TableContainer = ({ columns, data, setcargarGrilla, setshowBuscarPersona, setidpersona, setnombreyapellido, setidpersonaSeleccionada, setnombreyapellidoSeleccionada, updateMyData, skipPageReset, editable, selectedId, setselectedId }) => {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
    //defaultColumn,
    defaultColumn: { Filter: DefaultColumnFilter },
    editable,

    //initialState:{ hiddenColumns: ['Curso.Pk_Curso'] }
  }, useFilters, useSortBy);
  
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ðŸ”½' : ' ðŸ”¼') : '';
  };
  //console.log(data);
    
  return (
    <Table bordered hover {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              //Basico
              //<th {...column.getHeaderProps()}>{column.render('Header')}</th>

              //Ordenar por
              //<th {...column.getHeaderProps(column.getSortByToggleProps())}>
              //  {column.render("Header")}
              //  {generateSortingIndicator(column)}
              //</th>
              
              //Filtrar por
              <th {...column.getHeaderProps()}>
              <div {...column.getSortByToggleProps()}>
                {column.render("Header")}
                {generateSortingIndicator(column)}
              </div>
              <Filter column={column} />
            </th>

            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          
          return (
            <tr {...row.getRowProps({
              style:{
                background: selectedId == row.id ? '#4285F4' : '',
                color: selectedId == row.id ? 'white' : ''
              },
              onClick: () => {
                //setidpersona(row.values.pk_persona);
                setidpersonaSeleccionada(row.values.pk_persona);
                //console.log('it produced this event:', row.id) 
                setselectedId(row.id);
                setnombreyapellidoSeleccionada(row.values.Nombre + ' ' + row.values.Apellido);
               // console.log('it produced this event:', row.values) 
              },  
              onDoubleClick: () => {
                setidpersona(row.values.pk_persona)
                setnombreyapellido(row.values.Nombre + ' ' + row.values.Apellido)
                setshowBuscarPersona(false);
                //setcargarGrilla(true);
              }})}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
 
function BuscarPersonaPage({posicionarTipopersona, setcargarGrilla, setshowBuscarPersona, setidpersona, setnombreyapellido, setidpersonaSeleccionada, setnombreyapellidoSeleccionada}) {
  //const inputReference = useRef(null);
  //console.log(posicionarTipopersona);
  const [selectedId, setselectedId] = useState(-1);
  const [datos, setDatos] = React.useState([]);
  const [idfiltro, setidfiltro] = React.useState();
  const [nombrefiltro, setnombrefiltro] = React.useState("");
  const [apellidofiltro, setapellidofiltro] = React.useState("");
  const [nrodocfiltro, setnrodocfiltro] = React.useState("");
  const [tipopersonafiltro, settipopersonafiltro] = React.useState(posicionarTipopersona);

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [tipopersonatext, settipopersonatext] = useState("Tipo persona");
  const [datosTP, setDatosTP] = useState([]);
  
  function ObtenerTipopersonaDescripcion ()  {
    //console.log(posicionarTipopersona);
    const Query = gql` query obtenerDatosTP ($tipopersona: Int!) {
      condicionalTipopersona(tipopersona: $tipopersona,) {
        Pk_tipopersona
        Descripcion
      }
    }`;
    const variables = { tipopersona: posicionarTipopersona };
    const { loading, error, data } = useQuery(Query, { variables, fetchPolicy: "network-only" },);

    useEffect(() => {
        if(data) {
          const datos2 = data.condicionalTipopersona;
          //console.log(datos2);
          settipopersonatext(datos2[0].Descripcion);
        }
    }, [data]);

    if (loading) return <Alert color="info">
       Cargando...
    </Alert>;
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
  } 
  if (typeof(posicionarTipopersona) !== 'undefined' && posicionarTipopersona != null && posicionarTipopersona != "") {
    ObtenerTipopersonaDescripcion();
  }

  const QueryPersonas = gql` query obtenerDatos($idfiltro: String, $nombrefiltro: String, $apellidofiltro: String, $nrodocfiltro: String, $tipopersonafiltro: Int) {
    filtrarPersonas (idfiltro: $idfiltro, nombrefiltro: $nombrefiltro, apellidofiltro: $apellidofiltro, nrodocfiltro: $nrodocfiltro, tipopersonafiltro: $tipopersonafiltro) {
      pk_persona
      Nombre
      Apellido
      Nrodocumento
      
    }
  }`;

  const variables = { idfiltro: idfiltro, nombrefiltro: nombrefiltro, apellidofiltro: apellidofiltro, nrodocfiltro: nrodocfiltro, tipopersonafiltro: tipopersonafiltro };
  /* const [loadPersonas] = useLazyQuery(
    QueryPersonas,
    { variables, 
      fetchPolicy: "network-only", 
      onCompleted: data1 => { 
        //console.log(data1);
        setDatos(data1.allpersonas);
      }}
  ); */

  const { loading, error, data } = useQuery(QueryPersonas, {variables, fetchPolicy: "network-only", });
  //console.log(data);

  useEffect(() => {
    if(data) {
      setDatos(data.filtrarPersonas);
      if (data.filtrarPersonas.length >0) {
        //const datos1 = data.allpersonas;
        //console.log(data.filtrarPersonas);
        
        //console.log(data.filtrarPersonas[0].Nombre);
       
        //setidpersona(data.filtrarPersonas[0].pk_persona || '');
        //setnombreyapellido(data.filtrarPersonas[0].Nombre + ' ' + data.filtrarPersonas[0].Apellido);
        setidpersonaSeleccionada(data.filtrarPersonas[0].pk_persona || '');
        setnombreyapellidoSeleccionada(data.filtrarPersonas[0].Nombre + ' ' + data.filtrarPersonas[0].Apellido);
        setselectedId(0);
        setselectedId(0);
        //setnombre(datos1.)
      }
    }
  }, [data]);

  const columns = React.useMemo(
    () => [
          
          {
            Header: 'Nombre',
            accessor: 'Nombre',
            disableFilters: true,
          },
          {
            Header: 'Apellido',
            accessor: 'Apellido',
            disableFilters: true
          },
          {
            Header: 'Nro. Doc.',
            accessor: 'Nrodocumento',
            disableFilters: true
          },
          {
            Header: 'ID',
            accessor: 'pk_persona',
            disableFilters: true
          },
    ],
    []
  )

  //const data = React.useMemo(() => makeData(100000), [])
  function Volver()  {
    //loadPersonas();
  }
  
  function handleChangeId(e) {
    //console.log(e.target.value);
    setidfiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeNombre(e) {
    //console.log(e.target.value);
    setnombrefiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeApellido(e) {
    //console.log(e.target.value);
    setapellidofiltro(e.target.value)
    //loadPersonas();
  }

  function handleChangeNroDoc(e) {
    //console.log(e.target.value);
    setnrodocfiltro(e.target.value)
    //loadPersonas();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // ðŸ‘‡ Get input value
      //console.log("ENTER");
      //console.log(data);
      setidpersona(data.filtrarPersonas[0].pk_persona || '');
      setnombreyapellido(data.filtrarPersonas[0].Nombre + ' ' + data.filtrarPersonas[0].Apellido);
      setshowBuscarPersona(false);

      //setcargarGrilla(true);
    }
  };

  function ObtenerDatosTipopersona ()  {
    const Query = gql` query obtenerDatosTP {
      alltipopersona {
        Pk_tipopersona
        Descripcion
      }
    }`;
    
    const { loading, error, data } = useQuery(Query, {fetchPolicy: "network-only" },);

    useEffect(() => {
        if(data) {
          const datos1 = data.alltipopersona;
          setDatosTP(datos1);
          
        }
    }, [data]);

    if (loading) return <Alert color="info">
       Cargando...
    </Alert>;
    
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
    }
  } 

  ObtenerDatosTipopersona();

  const MostrarComboTipoPersona = () => {
    
    //console.log(posicionarTipopersona);

    const handleClick = (e) => {
        //console.log(e.target.value);
        var wtipopersona =  parseInt(e.target.value);
        if (wtipopersona) {
          settipopersonatext(e.target.innerText);
          settipopersonafiltro(wtipopersona)
          //loadAsignaturas();
        }
    } 
    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret color="primary">
                {tipopersonatext}
              </DropdownToggle>          
              <DropdownMenu>
                  {datosTP.map(item => (
                      <DropdownItem
                          key={item.Pk_tipopersona}
                          value={item.Pk_tipopersona}
                          onClick={handleClick}>
                        {item.Descripcion}
                      </DropdownItem>
                  ))}
              </DropdownMenu>
        </ButtonDropdown>
    )
  }
 
  return (
    <Container style={{ marginTop: "15px" }}>
    
      <Form style={{ marginBottom: "15px" }}>
        <Row>
            
            <Col >
              <Input type="text" autoFocus={true} name="nombre" id="nombre" onChange={handleChangeNombre} onKeyDown={handleKeyDown} placeholder="Nombre"/>
            </Col>
            <Col >
              <Input type="text" name="apellido" id="apellido" onChange={handleChangeApellido} onKeyDown={handleKeyDown} placeholder="Apellido"/>
            </Col>
            <Col >
              <Input type="text" name="nrodoc" id="nrodoc" onChange={handleChangeNroDoc} onKeyDown={handleKeyDown} placeholder="Nro. doc."/>
            </Col>
            <Col>
              <Input type="text"  name="idpersonaFiltro" id="idpersonaFiltro" onChange={handleChangeId} onKeyDown={handleKeyDown} placeholder="ID"  />
            </Col>
            <Col >
              {MostrarComboTipoPersona ()}
            </Col>
        </Row>
      </Form>
      
      <TableContainer 
        columns={columns} 
        data={datos}  
        setshowBuscarPersona = {setshowBuscarPersona}
        setcargarGrilla = {setcargarGrilla}
        setidpersona={setidpersona}
        setnombreyapellido = {setnombreyapellido}
        setidpersonaSeleccionada={setidpersonaSeleccionada}
        setnombreyapellidoSeleccionada = {setnombreyapellidoSeleccionada}
        selectedId  = {selectedId}
        setselectedId = {setselectedId}
      />
     
    </Container>
    
  )
}

export default BuscarPersonaPage;