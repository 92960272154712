import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { setAccessToken, getURL } from '../AccessToken';
import Udslogo from "../img/logo_uds.png"
import { useHistory } from "react-router-dom";

import { gql, useQuery } from '@apollo/client';
import {getURLReportes} from '../AccessToken';

//import gql from 'graphql-tag';
//import {useQuery} from 'react-apollo';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert} from 'reactstrap';

function LoginPage(props) {
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);
    const history = useHistory();
    const [datosTipoPersona, setdatosTipoPersona] = useState([]);
    const [TipoPersonatext, setTipoPersonatext] = useState("");
    const [TipoPersona, setTipoPersona] = useState();
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const submitHandler = (event) => {
        event.preventDefault(); //para que no se envie
        //const email = this.userEl.current.value;
        //const password = this.passwordEl.current.value;
        
         if (username.value.trim().length ===0 || password.value.trim().length ===0) {
             return;
         }
        //console.log(username.value);

        const requesbody = {
            query: `
                mutation {
                    loginUds (
                        username: "${username.value}"
                        password: "${password.value}"
                        tipopersona: ${TipoPersona}
                    ) 
                    { 
                        accessToken
                    }
                }
            `, 
        };
        //console.log(requesbody);
        const serverurl = getURL()+'/graphql';
        fetch(serverurl, {
            method: 'POST',
            body: JSON.stringify(requesbody),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(res => {
            if (res.status !== 200 && res.status !== 201 ) {
                throw new Error('Fallo');
            }
            //console.log("CORRECTO");
            //console.log(res);
            return res.json();
        })
        .then(resData => {
            //console.log(resData);
            //console.log("ACCES TOKEN IN LOGIN: " + resData.data.loginUds.accessToken);
            if (resData.errors) {
                setError(resData.errors[0]);
                return null;
                //console.log(resData.errors[0].message);
            }
            //GUARDAMOS EL TOKEN GENERADO AL LOGUEARNOS EN UNA VARIABLE DENTRO DE UNA FUNCIÃ“N
            setAccessToken(resData.data.loginUds.accessToken);
            //varÂ decodedÂ =Â jwt_decode(resData.data.loginUds.accessToken);
            //console.log(decoded);

            fetch(getURLReportes() + `/reportes/checklogin.jsp?usuario=${username.value}&tipopersesion=${TipoPersona}&idses=${resData.data.loginUds.accessToken}`, {
                method: "GET",
                mode: 'no-cors',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(resDataF => {
                console.log(JSON.stringify(resDataF));
            })
            .catch(errF => {
                console.log(errF);
            }) 
 
            //window.location.href = "http://localhost/proyecto/reportes/checklogin.jsp?data=carlos";
            if (TipoPersona==1){
                history.push('/notas');
            }
            else{
                history.push('/homologaciones');
            }
            
            
            //return <Redirect to='/notas' />;
            //this.props.history.push('/notas');
            //console.log("ACCES TOKEN IN LOGIN: " + getAccessToken());
        })
        .catch(err => {
            console.log(err);
        })

        /* const usuario = useContext(UserContext)     
        console.log("USER EN CONTEXT " + JSON.stringify(usuario)); */
    }

    function  ObtenerDatosTipopersona ()  {
        const Query = gql` query obtenerDatos { 
            alltipopersona {
                Pk_tipopersona
                Descripcion
            }}`;
        
        const { loading, error, data } = useQuery(Query,
          {fetchPolicy: "network-only" });
           useEffect(() => {
            if(data) {
              const datos1 = data.alltipopersona;
              setTipoPersonatext(datos1[0].Descripcion)
              setTipoPersona(datos1[0].Pk_tipopersona)
              //console.log(datos1);
              setdatosTipoPersona(datos1);
            }
          }, [data]);
    
        if (loading) return 'Loading...';
        
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
        }
    } 

    const MostrarComboTipoPersona = () => {
        const handleClick = (e) => {
            setTipoPersonatext(e.target.innerText);
            setTipoPersona(e.target.value);
            console.log(TipoPersona);
        } 
        return (
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown-login" >
              <DropdownToggle caret  >
                {TipoPersonatext}
              </DropdownToggle>          
              <DropdownMenu >
                  {datosTipoPersona.map(item => (
                      <DropdownItem 
                          key={item.Pk_tipopersona}
                          value={item.Pk_tipopersona}
                          onClick={handleClick}
                          >
                              
                        {item.Descripcion}
                      </DropdownItem>
                  ))}
              </DropdownMenu>
            </ButtonDropdown>
        )
    }
    ObtenerDatosTipopersona();

    return (
        <div className="container p-4" >
            <div className="row" >
                <div className="col-md-4 mx-auto">
                    <div className="card text-center">
                        <div className="card-header"> 
                            <h3>Iniciar sesiÃ³n </h3>
                        </div>
                        
                        <Form className="form p-3" onSubmit={submitHandler}>
                            <img src={Udslogo} alt="Logo UDS" className="card-img-top p-3 mx-auto m-2  w-50"></img>
                            
                            <Form.Group className="mb-2" controlId="formBasicUser">
                                {MostrarComboTipoPersona()}
                            </Form.Group>
                            
                            <Form.Group className="mb-2" controlId="formBasicUser">
                                <Form.Control {...username} placeholder="Usuario" autoFocus  required={true}  />
                                
                            </Form.Group>
                            
                            <Form.Group className="mb-2" controlId="formBasicPassword">
                                <Form.Control type="password" {...password} placeholder="ContraseÃ±a" required={true} />
                                <Form.Text className="text-muted">
                                    Nunca compartas tu contraseÃ±a
                                </Form.Text>
                                
                            </Form.Group>
                        
                            <div>{error ? <Alert color="danger">{error.message}</Alert>:""}</div>

                            <Button className="btn-block" variant="primary" type="submit">
                                Entrar
                            </Button>
                        </Form>
                        
                    </div>
                </div>
            </div>
        </div>
    );
  }
   
  const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

export default LoginPage;