import React, { useEffect, useRef, useMemo , useState, useContext } from 'react';
import UserContext from '../componets/userContext';
import {Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Badge, Label } from 'reactstrap';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

//import TableContainer from './TableContainer_sinPag';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button  } from '@mui/material';
//import MaterialReactTable from 'material-react-table';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const csvConfig = mkConfig({
  filename: 'Inscripciones',
  fieldSeparator: ';',
  decimalSeparator: '.',
  //showColumnHeaders:	true,
  //useKeysAsHeaders: true,
  columnHeaders: [{key: "tipocarrera", displayLabel: "Tipo"}, {key: "convocatoria", displayLabel: "Convocatoria"}, {key: "carrera", displayLabel: "Carrera"}, {key: "curso", displayLabel: "AÃ±o"}, {key: "alumno", displayLabel: "Alumno"}, {key: "cantidadInscriptos", displayLabel: "Inscriptos"}]
});

const GestionTitulos = () => {
    const usuario = useContext(UserContext)  
    const persona = usuario.persona.pk_persona;
    let nomApel = '';
    nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;

    const [datos, setDatos] = useState([]);
    const [MensajeError, setMensajeError] = useState("");
    
    
    const [aniolectivotext, setAniolectivotext] = useState("AÃ±o lectivo");
    const [datosAl, setDatosAl] = useState([]);
    const [aniolectivo, setAniolectivo] = useState(0);

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [total, setTotal] = useState("0");

    const [valueComp, setValueComp] = React.useState("");
    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const toDay= new Date().toISOString().substring(0, 10);

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(datos);
      download(csvConfig)(csv);
    };

    const QueryDcc = gql`query Obtenergestionestitulo {
      obtenergestionestitulo {
        pk_gestiontitulo
        resolucionnro
        resolucionfecha
        paqueteNro
        Gestionar
        persona
        nombre
        apellido
        nrodocumento
        CarreraDescrip
       
      }
    }`;

    const [cargarGrilla, { loading, error, data }] = useLazyQuery(
        QueryDcc,
          { variables: { aniomalla: aniolectivo},
          fetchPolicy: "network-only", }
    );

    function ObtenerDatosAnioLectivo ()  {
      const Query = gql` query obtenerDatos { 
        Aed_allanhomalla{
          anho
      }}`;
          
      const { loading, error, data } = useQuery(Query,
        {fetchPolicy: "network-only" });
          useEffect(() => {
          if(data) {
            const datos1 = data.Aed_allanhomalla;
            //console.log(datos1);
            setDatosAl(datos1);
          }
        }, [data]);
      
        if (loading) return 'Loading...';
          
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
      }
    } 
      
    ObtenerDatosAnioLectivo();

    
    const MostrarComboAnioLectivo = () => {
      const handleClickInsti = (e) => {
            setMensajeError("");
            setAniolectivotext(e.target.innerText);
            //console.log(e.target.value)
            var wal =  parseInt(e.target.value);
            if (wal) {
              setAniolectivo(wal);
              //ObtenerDatosAnioLectivo();
            }  
            
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
            <DropdownToggle caret>
              {aniolectivotext}
            </DropdownToggle>          
            <DropdownMenu>
              {datosAl.map(item => (
                  <DropdownItem 
                    key={item.anho}
                    value={item.anho}
                    onClick={handleClickInsti}>
                    {item.anho}
                  </DropdownItem>
              ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }


  const theme = useTheme(); //replace with your theme/createTheme
  
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  const columns = useMemo(() => {
    
    var arrayColumnas =  [
      {
        header: 'Nombre',
        accessorKey: 'nombre',
      },
      {
        header: 'Apellido',
        accessorKey: 'apellido',
      },
      {
        header: 'Documento',
        accessorKey: 'nrodocumento',
      },
      {
        header: 'Carrera',
        accessorKey: 'CarreraDescrip',
      },
      {
        header: 'Nro. Paquete',
        accessorKey: 'paqueteNro',
      },
      {
        header: 'Fecha ResoluciÃ³n',
        accessorKey: 'resolucionfecha',
      },
      {
        header: 'Nro. ResoluciÃ³n',
        accessorKey: 'resolucionnro',
      },
      
    ]

    return arrayColumnas}, [datos]
  );

  const table = useMaterialReactTable({
    id: "table-to-xls",
    columns,
    data: datos,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        enableResizing: true,
      },
    },
    //enableColumnResizing: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    enableGlobalFilter: false,
    enableColumnFilters :true,
    enableGrouping :true,

    initialState: {
      density: 'compact',
      expanded: false, //expand all groups by default
      //grouping: ['carrera', 'curso'], //an array of columns to group by by default (can be multiple)
      //columnVisibility: { tipocarrera: false, convocatoria: false }
      //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
       showColumnFilters: true
    },
       
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTableContainerProps: { sx: { maxHeight: 640 } },
    localization: MRT_Localization_ES,    

    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       gap: '16px',
    //       padding: '8px',
    //       flexWrap: 'wrap',
    //     }}
    //   >
    //     <Button
    //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //       onClick={handleExportData}
    //       startIcon={<FileDownloadIcon />}
    //     >
          
    //     </Button>
        
    //   </Box>
    // ),


  });
  
  useEffect(() => {
    cargarGrilla();
  }, []);

      //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
      if(data) {
        //console.log(data);
        const datosUsu = data.usuarioLogueado
        //set 
        setDatos(data.obtenergestionestitulo);
      }
  }, [data]);

  if (loading) return <Alert color="info">
          Cargando...
        </Alert>
    if (error) {
      console.log(error.message)
      return (
        <Alert color="danger">
          {error.message}
        </Alert>
      )
  }
    
  return (
    <div >


          <MaterialReactTable
            table={table} //only pass in table instead of all table options
            
          />


    </div >
    )
};

export default GestionTitulos;