import React,  { useEffect, useMemo , useState, useContext } from 'react';
//import gql from 'graphql-tag';
import {Container, CardTitle, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Alert, Form,Card, Input, Row,  Col } from 'reactstrap';
//import {useQuery, useLazyQuery} from 'react-apollo';
import { gql, useQuery, useLazyQuery } from '@apollo/client';

import UserContext from '../componets/userContext';
import * as Icon from 'react-bootstrap-icons';
import "../css/styles.css";
//import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import BuscarPersonaPage from './Page_buscarPersona';
import {getURLReportes} from '../AccessToken';
import TableContainer from './TableContainer_sinPag';
import { SelectColumnFilter } from './filters';
import { Box, Button as MaterialButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Typography } from '@mui/material';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const csvConfig = mkConfig({
  filename: 'Homologaciones',
  fieldSeparator: ';',
  decimalSeparator: '.',
  //showColumnHeaders:	true,
  //useKeysAsHeaders: true,
  columnHeaders: [{key: "anioDescrip", displayLabel: "AÃ±o"}, {key: "Alias", displayLabel: "Asignatura"}, {key: "AsignaturaOrigen", displayLabel: "Asignatura Origen"}, {key: "nroActa", displayLabel: "Nro. Acta"}, {key: "nroActaRepeticiones", displayLabel: "Repeticiones"}, {key: "fechaaprobacion", displayLabel: "Fecha AprobaciÃ³n"}, {key: "notafinal", displayLabel: "Nota"}, {key: "calificacion", displayLabel: "CalificaciÃ³n"}, {key: "tipo", displayLabel: "Tipo"}]
});

function HomologacionesPage(props) {
    const usuario = useContext(UserContext)  
    let persona = '';
    let tipopersona = usuario.persona.personatipopers[0].TipoPersona.Pk_tipopersona;
    let nomApel = '';
    let BotonBuscarPersonaDisable = false;
    //if (tipopersona==1) {
      persona = usuario.persona.pk_persona;
    //  nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;
      //BotonBuscarPersonaDisable = true;
    //}
    //console.log("USER EN CONTEXT " + JSON.stringify(usuario));
    const [datos, setDatos] = useState([]);

    const [disablebtnPersona, setDisablebtnPersona] = React.useState(BotonBuscarPersonaDisable);
    const [idpersona, setidpersona] = useState('');
    const [nombreyapellido, setnombreyapellido] = useState("");
    
    const [CantidadHomologaciones, setCantidadHomologaciones] = useState("");
    const [CantidadPendientes, setCantidadPendientes] = useState("");

    const [showBuscarPersona, setshowBuscarPersona] = useState(false);

    const [datosAniolec, setdatosAniolec] = useState([]);
    const [carreratext, setcarreratext] = useState("Carrera");
    const [carrera, setcarrera] = useState();
    const [dropdownOpenAL, setdropdownOpenAL] = useState(false);
    const toggleAL = () => setdropdownOpenAL(!dropdownOpenAL);

    //const [reporte, setreporte] = useState("");
    const [MensajeError, setMensajeError] = useState("");

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(datos);
      download(csvConfig)(csv);
    };

    const [idpersonaSeleccionada, setidpersonaSeleccionada] = useState('');
    const [nombreyapellidoSeleccionada, setnombreyapellidoSeleccionada] = useState("");


    let tipopersonaBuscador = 2;

    const [cargarGrilla, setcargarGrilla] = useState(false);

    // const [ObtenerDatoscarrera, { dataAL }]  = useLazyQuery(QueryAL, {
    //   variables: { 
    //     aniopensum: 2024}, fetchPolicy: "network-only",
    //     onCompleted: dataAL => {
    //       setdatosAniolec(dataAL.allpensuncarreraUDS);
    //    },
    // });

    const MostrarBotonBuscarPersona = () => {
      const handleClick = (e) => {
          setcargarGrilla(false);
          setshowBuscarPersona(true);
      }
      return (
        <Button color="primary" onClick={handleClick} disabled={disablebtnPersona}><Icon.Search /></Button>
      )
    }

    const MostrarCombocarrera = () => {
      const handleClick = (e) => {

        if (idpersona == ''){
          setMensajeError("Seleccionar alumno");
        }
        else {
          setMensajeError("");
          setcarreratext(e.target.innerText);
          var wcarrera = parseInt(e.target.value);
          //console.log(e.target.value);
          if (wcarrera) {
            setcarrera(wcarrera);
            //MostrarDatosCiclo()
            setcargarGrilla(true);
            //cargarGrillaHomologacion();
          }  
        }
      } 
      return (
          <ButtonDropdown isOpen={dropdownOpenAL} toggle={toggleAL} className="my-dropdown">
            <DropdownToggle caret >
              {carreratext}
            </DropdownToggle>          
            <DropdownMenu>
                {datosAniolec.map(item => (
                    <DropdownItem
                        key={item.Pk_PensumCarrera}
                        value={item.Pk_PensumCarrera}
                        onClick={handleClick}>
                            
                      {item.Carrera.Descripcion} {item.Aniopensum}
                    </DropdownItem>
                ))}
            </DropdownMenu>
          </ButtonDropdown>
      )
    }

    function ObtenerDatosCarrera ()  {
      const QueryC = gql` query AllpensuncarreraUDS($aniopensum: Int) {
        allpensuncarreraUDS(Aniopensum: $aniopensum) {
          Pk_PensumCarrera
          Aniopensum
          Carrera {
            Pk_Carrera
            Descripcion
            Siglas
          }
        }
      }`;

        const { loading, error, data } = useQuery(QueryC, {variables: {aniopensum: 2024}, fetchPolicy: "network-only" });

        useEffect(() => {
            if(data) {
              //const datos1 = data.allpensuncarreraUDS;
              //console.log(datos1);
              setdatosAniolec(data.allpensuncarreraUDS);
            }
        }, [data]);
    
        if (loading) return 'Loading...';
        
        if (error) {
          console.log(error.message)
          return (
            <Alert color="danger">
              {error.message}
            </Alert>
          )
        }
    } 

    ObtenerDatosCarrera();
    
    async function cerrarBuscarPersona() {
      //console.log("LLEGO CERRAR BUSCAR PERSONA")
      setMensajeError("");
      setidpersona(idpersonaSeleccionada);
      setnombreyapellido(nombreyapellidoSeleccionada);
      //loadCursado();
      setshowBuscarPersona(false);
      
    }

    const QueryDcc = gql`query ObtenerHomologaciones($pensumcarrera: Int!, $persona: Int!) {
      obtenerHomologaciones(pensumcarrera: $pensumcarrera, persona: $persona) {
        Alias
        Asignatura
        AsignaturaOrigen
        anioDescrip
        nroActa
        fechaaprobacion
        notafinal
        calificacion
        tipo
        nroActaRepeticiones
      }
    }`;

    const [cargarGrillaHomologacion, { loading, error, data }] = useLazyQuery(
        QueryDcc,
          { variables: { pensumcarrera: carrera, persona: idpersona},
          fetchPolicy: "network-only", 
            // onCompleted: data => {
            //  //console.log("Bandera" + cargarGrilla);
            //  //console.log('data ', data);
            //  let wcontarhomologados = 0;
            //  const datos1 = data.obtenerHomologaciones;
            //  for (const item of datos1) {
            //     if (item.calificacion) {
            //       wcontarhomologados = wcontarhomologados + 1;
            //     }
            //  }
            //  setCantidadHomologaciones(wcontarhomologados);
            //  console.log("HOMOL " + CantidadHomologaciones);
            // }
            
        }
    );

    const columns = useMemo(() => {
      var arrayColumnas =  [
        {
          header: 'AÃ±o',
          accessorKey: 'anioDescrip',
        },
        {
          header: 'Asignatura',
          accessorKey: 'Alias',
        },
        {
          header: 'Asignatura Origen',
          accessorKey: 'AsignaturaOrigen',
        },
        {
          header: 'Nro. Acta',
          accessorKey: 'nroActa',
        },
        {
          header: 'Repeticiones',
          accessorKey: 'nroActaRepeticiones',
        },
        {
          header: 'Fecha AprobaciÃ³n',
          accessorKey: 'fechaaprobacion',
        },
        {
          header: 'Nota',
          accessorKey: 'notafinal',
        },
        {
          header: 'CalificaciÃ³n',
          accessorKey: 'calificacion',
        },
        {
          header: 'Tipo',
          accessorKey: 'tipo',
        },
      ]

      return arrayColumnas}, [datos]
    );
    
    const table = useMaterialReactTable({
      id: "table-to-xls",
      columns,
      data: datos,
      displayColumnDefOptions: {
        'mrt-row-expand': {
          enableResizing: true,
        },
      },
      //enableColumnResizing: true,
      enableStickyHeader: true,
      enableStickyFooter: true,
      enablePagination: false,
      enableGlobalFilter: false,
      enableColumnFilters :true,
      enableGrouping :true,

      initialState: {
        density: 'compact',
        expanded: false, //expand all groups by default
        //grouping: ['carrera', 'curso'], //an array of columns to group by by default (can be multiple)
        columnVisibility: { AsignaturaOrigen: false},
        //sorting: [{ id: 'carrera', desc: false }], //sort by state by default
        showColumnFilters: true
      },
        
      muiToolbarAlertBannerChipProps: { color: 'primary' },
      muiTableContainerProps: { sx: { maxHeight: 640 } },
      localization: MRT_Localization_ES,    
      renderTopToolbarCustomActions: ({ table }) => (
 
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <MaterialButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
          > 
          </MaterialButton>
          <Typography variant="h6">A homologar : {CantidadHomologaciones} - Pendientes : {CantidadPendientes}</Typography>
        </Box>
        
      ),
    });

    useEffect(() => {
      if(cargarGrilla===true) {
        console.log("carga Grilla");
        cargarGrillaHomologacion();
      }
    }, [cargarGrilla]);

    //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
    useEffect(() => {
      if(data) {
        console.log(data);
        //set 
        setDatos(data.obtenerHomologaciones);

        // function ContarRepeticiones(datos) {
        //   const contador = items.reduce((accumulator, item) => {
        //     return accumulator = (item.quantity * item.price);
        //   }, 0);
        //   return contador
        // }

       

        
        //datos.reduce((contador, item) => (contador = ++prev[curr.nroActa] || 1, prev), {})
       // console.log(ContarRepeticiones(data.obtenerHomologaciones));
        //console.log("CANTIDAD ASIG " + total);
        
        let wcontarhomologados = 0;
        const datos1 = data.obtenerHomologaciones;
        for (const item of datos1) {
          if (item.calificacion) {
             wcontarhomologados = wcontarhomologados + 1;
          }
        }
        let wpendientes = data.obtenerHomologaciones.length - wcontarhomologados;
        setCantidadHomologaciones(wcontarhomologados);
        setCantidadPendientes(wpendientes);
      }
    }, [data]);

    // if (loading) return <Alert color="info">
    //         Cargando...
    //       </Alert>
      if (error) {
        console.log(error.message)
        return (
          <Alert color="danger">
            {error.message}
          </Alert>
        )
    }

    return (
      <div>
        <Container>
        <Col className="col-md-8 mx-auto">
              <Card className="text-center">
                <Container className="mt-1">
                    <Form>
                      <FormGroup>
                        <FormGroup row>
                          <Col sm={2} className='mt-1'>
                            <Input type="text" name="idpersona" id="idpersona" placeholder="Id" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={idpersona} required={true} />
                          </Col>
                          <Col sm={8} className='mt-1'>
                            <Input type="text" name="nombrepersona" id="nombrepersona" placeholder="Nombre y apellido" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={nombreyapellido} required={true} />
                          </Col>
                          <Col sm={2} className='mt-1'>
                            {MostrarBotonBuscarPersona()}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col  className='mt-1'>{MostrarCombocarrera()} </Col>
                        </FormGroup>

                        <Row>{MensajeError ? <Alert color="danger" className="my-dropdown">{MensajeError}</Alert>:""}</Row>
                        
                      </FormGroup>

                    </Form>
                </Container>
              </Card>
          </Col>
          </Container>
          <div className="mt-1">
            <MaterialReactTable
              table={table} //only pass in table instead of all table options
            />
          </div>
          
          <Modal show={showBuscarPersona} onHide={cerrarBuscarPersona} size="lg">
              <Modal.Header>
                <Modal.Title>Busqueda de persona</Modal.Title>
                </Modal.Header>
              <Modal.Body>
                  <BuscarPersonaPage posicionarTipopersona = {tipopersonaBuscador} setcargarGrilla = {setcargarGrilla} setshowBuscarPersona = {setshowBuscarPersona} setidpersona = {setidpersona} setnombreyapellido={setnombreyapellido} setidpersonaSeleccionada = {setidpersonaSeleccionada} setnombreyapellidoSeleccionada = {setnombreyapellidoSeleccionada}/>
              </Modal.Body>
                <Modal.Footer>
                  
                  <Button color="primary" onClick={cerrarBuscarPersona}>
                    Aceptar
                  </Button>
              </Modal.Footer>
          </Modal>

      </div>
    )
}
export default HomologacionesPage;