import React, { useEffect, useRef, useMemo , useState, useContext } from 'react';

import TableContainer from './Table_material-react-table';
//import gql from 'graphql-tag';
import {Container, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Row, Alert, Col, Input, Button, Badge, Label } from 'reactstrap';
import UserContext from '../componets/userContext';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
//import { useLazyQuery } from 'react-apollo';
//import {useQuery} from 'react-apollo';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import * as Icon from 'react-bootstrap-icons';
import { SelectColumnFilter } from './filters';
//import MaterialReactTable from 'material-react-table';
//import MaterialTableContainer from './tableMaterial';
//import MaterialReactTable from 'material-react-table';
//Import Material React Table Translations
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_ES } from 'material-react-table/locales/es';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from 'react-bootstrap/Modal';
import BuscarPersonaPage from './Page_buscarPersona';

//Material-UI Imports
import {
  Box,
  ListItemIcon,
  MenuItem,
  Typography,
  TextField,
  Stack
} from '@mui/material';

const Example = () => {
    const usuario = useContext(UserContext)  
    const persona = usuario.persona.pk_persona;
    let nomApel = '';
    nomApel = usuario.persona.Nombre + ' ' + usuario.persona.Apellido;
    let BotonBuscarPersonaDisable = false;

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [showMensaje, setshowMensaje] = useState(false);
    const handleCloseMensaje = () => {
        setshowMensaje(false);
    };
    const [disablebtnPersona, setDisablebtnPersona] = React.useState(BotonBuscarPersonaDisable);

    const [idpersona, setidpersona] = useState(persona);
    const [nombreyapellido, setnombreyapellido] = useState(nomApel);
    const [datos, setDatos] = useState([]);

    const [MensajeError, setMensajeError] = useState("");

    const [datosAl, setDatosAl] = useState([]);
    //const [originalData, setoriginalData] = useState([]);
    // declaramos la bandera de ediciÃ³n
    //const [editable, setEditable] = useState(false);
    const editable= false;
    //const [usuario, setUsuario] = useState([]);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const [datosInsti, setDatosInsti] = useState([]);
    const [instituciontext, setinstituciontext] = useState("InstituciÃ³n");
    const [institucion, setinstitucion] = useState(3);
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [datosSede, setDatosSede] = useState([]);
    const [sedetext, setsedetext] = useState("AsunciÃ³n");
    const [sede, setsede] = useState(1);
    const [dropdownOpenSede, setOpenSede] = useState(false);
    const toggleSede = () => setOpenSede(!dropdownOpenSede);

    const [datosTipopago, setDatosTipopago] = useState([]);
    const [tipopagotext, settipopagotext] = useState("Forma pago");
    const [tipopago, settipopago] = useState(1);
    const [dropdownOpenTipopago, setOpenTipopago] = useState(false);
    const toggleTipopago = () => setOpenTipopago(!dropdownOpenTipopago);

    const [datosTipocomprobante, setDatosTipocomprobante] = useState([]);
    const [Tipocomprobante, setTipocomprobante] = useState(1);
    const [Tipocomprobantetext, setTipocomprobantetext] = useState("Tipo comprobante");

    const [datosTipomov, setDatosTipomov] = useState([]);
    const [tipomov, settipomov] = useState();
    
    const togglereporte = () => setdropdownOpenreporte(!dropdownOpenreporte);
    const [dropdownOpenreporte, setdropdownOpenreporte] = useState(false);
    const [reportetextSaldos, setreportetextSaldos] = useState("Con saldo");
    const [estadoCuenta, setestadoCuenta] = useState(1);

    const toggleIE = () => setdropdownOpenIE(!dropdownOpenIE);
    const [dropdownOpenIE, setdropdownOpenIE] = useState(false);
    const [reportetextIE, setreportetextIE] = useState("Ingresos");
    const [ingresosEgresos, setingresosEgresos] = useState(1);

    const [checked, setChecked] = React.useState(true);
    const [checkResponsabletext, setCheckResponsabletext] = React.useState("Imprimir a nombre del responsable");

    const [validationErrors, setValidationErrors] = useState({});
    const [movimiento, setMovimiento] = useState(0);

    const [total, setTotal] = useState("0");

    const [valueComp, setValueComp] = React.useState("");
    
    //const inputRef = useRef();


    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

   
    const Query = gql`query obtenerdatosCtacte($persona: Int!, $institucion: Int!, $estadoCuenta: Int!) {
        ctactePorPersona(persona: $persona, institucion: $institucion, estadoCuenta: $estadoCuenta) {
          Pk_CtaCte
          FechaDate
          Pensumcarrera{
              Pk_PensumCarrera
              Carrera {
              Siglas
              }
          }
          Persona {
              pk_persona
          }
          Cuenta {
              Pk_cuenta
              Descripcion
              DebeHaber
          }
          Aranceles{
              Descripcion
          }
          getDetallectacte {
            Pk_DetalleCtaCte
            
          }
        }
    }`;
    const QueryDcc = gql`query obtenerdatosCtacte($persona: Int!, $institucion: Int!, $estadoCuenta: Int!) {
      DetallectactePorPersona(persona: $persona, institucion: $institucion, estadoCuenta: $estadoCuenta) {
        Pk_DetalleCtaCte
        VencimientoDate
        Descripcion
        Item
        MontoDebe
        MontoHaber
        Saldo
        montoMovimiento
        FechaUltimoPagoDate
        Observacion
        Porcentajeiva
        DetallePeriodo
        AnioLectivo
        Ctacte {
            Pk_CtaCte
            FechaDate
            Pensumcarrera{
              Pk_PensumCarrera
              Aniopensum
              Carrera {
                Siglas
              }
            }
            Persona {
              pk_persona
            }
            Cuenta {
              Pk_cuenta
              Descripcion
              DebeHaber
            }
            Aranceles{
              Descripcion
            }
        }
      }
  }`;

    const [loadAsignaturas, { loading, error, data }] = useLazyQuery(
      QueryDcc,
        { variables: { persona: 9719,
        institucion: 3, 
        estadoCuenta: estadoCuenta},
        fetchPolicy: "network-only", }
    );

const MostrarComboSaldos = () => {
    const handleClick = (e) => {
      //console.log(e.target.innerText);
      //console.log(e.target.value);
      setestadoCuenta(parseInt(e.target.value))
      //setMensajeError("");
      setreportetextSaldos(e.target.innerText);
      //var wreporte =  e.target.value;
      //if (wreporte) {
        //original.reporte = e.target.value;
        //console.log(reporte);
        loadAsignaturas();
      //}  
    } 
    return (
      <ButtonDropdown isOpen={dropdownOpenreporte} toggle={togglereporte} className="grilla-dropdown">
        <DropdownToggle caret >
          {reportetextSaldos}
        </DropdownToggle>          
        <DropdownMenu >
            <DropdownItem key="1" value="1" onClick={handleClick}>
              Con saldo
            </DropdownItem>
            <DropdownItem key="2" value="2" onClick={handleClick}>
              Sin saldo
            </DropdownItem>
            <DropdownItem key="3" value="3" onClick={handleClick}>
              Todos
            </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
}

const MostrarComboIE = () => {
  const handleClick = (e) => {
    //console.log(e.target.innerText);
    //console.log(e.target.value);
    setingresosEgresos(parseInt(e.target.value))
    //setMensajeError("");
    setreportetextIE(e.target.innerText);
    //var wreporte =  e.target.value;
    //if (wreporte) {
      //original.reporte = e.target.value;
      //console.log(reporte);
      loadAsignaturas();
    //}  
  } 
  return (
    <ButtonDropdown isOpen={dropdownOpenIE} toggle={toggleIE} className="grilla-dropdown">
      <DropdownToggle caret color="warning">
        {reportetextIE}
      </DropdownToggle>          
      <DropdownMenu >
          <DropdownItem key="1" value="1" onClick={handleClick}>
            Ingresos
          </DropdownItem>
          <DropdownItem key="2" value="2" onClick={handleClick}>
            Egresos
          </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

function ObtenerDatosInstitucion ()  {
    const Query = gql` query obtenerDatos { 
            institucionesAbiertas{
                Pk_Institucion
                Descripcion
    }}`;
        
    const { loading, error, data } = useQuery(Query,
      {fetchPolicy: "network-only" });
        useEffect(() => {
        if(data) {
          const datos1 = data.institucionesAbiertas;
          //console.log(datos1);
          setDatosInsti(datos1);
        }
      }, [data]);
    
      if (loading) return 'Loading...';
        
      if (error) {
        console.log(error.message)
        return (
          <Alert color="danger">
            {error.message}
          </Alert>
        )
    }
} 
    
ObtenerDatosInstitucion();

function ObtenerDatosSede ()  {
  const Query = gql` query obtenerDatos { 
    allSede {
      descripcion
      pk_sede
    }}`;
  
  const { loading, error, data } = useQuery(Query,
    {fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datos1 = data.allSede;
        //console.log(datos1);
        setDatosSede(datos1);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

ObtenerDatosSede();

function ObtenerDatosTipopago ()  {
  const Query = gql` query obtenerDatos { 
    allTipopago {
      Pk_TipoPago
      Descripcion
      Coeficiente
      PorcentajeARestarDelDescuento
    }}`;
  
  const { loading, error, data } = useQuery(Query,
    {fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datos1 = data.allTipopago;
        //console.log(datos1[0].Descripcion);
        settipopago(datos1[0].Pk_TipoPago);
        settipopagotext(datos1[0].Descripcion + " " + datos1[0].PorcentajeARestarDelDescuento + "%");
        //console.log(tipopago);
        setDatosTipopago(datos1);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

ObtenerDatosTipopago();

function ObtenerDatosTipocomprobante ()  {
  const Query = gql` query obtenerDatos($institucion: Int!, $sede: Int!) { 
    listaTipomprobante(institucion: $institucion, sede: $sede) {
      Pk_TipoFac
      Descripcion
      Mostrar
    }}`;
  
  const { loading, error, data } = useQuery(Query,
    {variables: { institucion: institucion,
      sede: sede }, fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datos1 = data.listaTipomprobante;
        //console.log(datos1[0].Descripcion);
        setTipocomprobante(datos1[0].Pk_TipoFac);
        setTipocomprobantetext(datos1[0].Descripcion);
        //console.log(datos1);
        setDatosTipocomprobante(datos1);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

ObtenerDatosTipocomprobante();


const MostrarComboTipopago = () => {
  const handleClickTipopago = (e) => {
    setMensajeError("");
    settipopagotext(e.target.innerText);
    //console.log(e.target.value)
    var wvalor =  parseInt(e.target.value);
    if (wvalor) {
        settipopago(wvalor);
          //ObtenerDatosAnioLectivo();
    }  
    loadAsignaturas();
  } 
  return (
    <ButtonDropdown isOpen={dropdownOpenTipopago} toggle={toggleTipopago} className="my-dropdown" >
        <DropdownToggle caret color="success">
          {tipopagotext}
        </DropdownToggle>          
        <DropdownMenu >
                {datosTipopago.map(item => (
                    <DropdownItem 
                        key={item.Pk_TipoPago}
                        value={item.Pk_TipoPago}
                        onClick={handleClickTipopago}>
                      {item.Descripcion} {item.PorcentajeARestarDelDescuento} %
                    </DropdownItem>
                ))}
        </DropdownMenu>
    </ButtonDropdown>
  )
}

function ObtenerDatosTipoMov ()  {
  const Query = gql` query obtenerDatos {
    allTipomovimiento {
      Pk_Tipomovimiento
      Descripcion
    }
  }`;
  
  const { loading, error, data } = useQuery(Query,
    {fetchPolicy: "network-only" });
     useEffect(() => {
      if(data) {
        const datostm = data.allTipomovimiento;
        //console.log(datostm);
        setDatosTipomov(datostm);
      }
    }, [data]);

  if (loading) return 'Loading...';
  
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
} 

ObtenerDatosTipoMov();

const MostrarComboInsti = () => {
    const handleClickInsti = (e) => {
          setMensajeError("");
          setinstituciontext(e.target.innerText);
           //console.log(e.target.value)
          var winsti =  parseInt(e.target.value);
          if (winsti) {
            setinstitucion(winsti);
            //ObtenerDatosAnioLectivo();
          }  
          loadAsignaturas();
    } 
    return (
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="my-dropdown" >
          <DropdownToggle caret>
            {instituciontext}
          </DropdownToggle>          
          <DropdownMenu>
            {datosInsti.map(item => (
                <DropdownItem 
                  key={item.Pk_Institucion}
                  value={item.Pk_Institucion}
                  onClick={handleClickInsti}>
                  {item.Descripcion}
                </DropdownItem>
            ))}
          </DropdownMenu>
      </ButtonDropdown>
    )
}

const ControlledSwitches = () => {
  //const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel 
      control={
        <Switch checked={checked} onChange={handleChange} name="jason" 
        />
      }
      label={
        <Typography
          variant="h5"
          sx={{
            ...(checked === false && {
              color: 'text.disabled',
              textDecoration: 'line-through'
            })
          }}
        >
          {checkResponsabletext}
        </Typography>
      }
    />
  );
}

const MostrarComboSede = () => {
  const handleClickSede = (e) => {
    setMensajeError("");
    setsedetext(e.target.innerText);
    //console.log(e.target.value)
    var wvalor =  parseInt(e.target.value);
    if (wvalor) {
        setsede(wvalor);
          //ObtenerDatosAnioLectivo();
    }  
    loadAsignaturas();
  } 
  return (
    <ButtonDropdown isOpen={dropdownOpenSede} toggle={toggleSede} className="my-dropdown" >
        <DropdownToggle caret color="primary">
          {sedetext}
        </DropdownToggle>          
        <DropdownMenu >
                {datosSede.map(item => (
                    <DropdownItem 
                        key={item.pk_sede}
                        value={item.pk_sede}
                        onClick={handleClickSede}>
                      {item.descripcion}
                    </DropdownItem>
                ))}
        </DropdownMenu>
    </ButtonDropdown>
  )
}

const theme = useTheme(); //replace with your theme/createTheme
  const columns = useMemo(() => {
    const EditableCell2 = ({
      cell, 
      row,
      column: { id },
      //updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
      
      //console.log('cell is:', row);
      // We need to keep and update the state of the cell normally
      const initialValue = row.original.montoMovimiento;
      const [value, setValue] = React.useState(initialValue);
      const [saldo, setSaldo] = React.useState(row.original.Saldo);
      //console.log("INITIAL" + value)
      
      //const {index} = row;
      //let disabled = "disabled";
      let disabled = "";
      if (row.original.MontoDebe === row.original.MontoHaber) {
        disabled = "disabled";
      }

      //const addCommas = (num) =>
      //num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      //const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

      const handleChange = (event) => {
        //setMessage(event.target.value);
        //console.log('cell is:', cell);
        //console.log('value is:', event.target.value);
        const valor = Math.round(removeNonNumeric(event.target.value));
        //console.log(valor)
        const {original} = cell.row;
        //console.log('datos is:');
        //console.log(datos);
        let montodebe = Math.round(original.MontoDebe);
        let saldo = montodebe - valor;
        setSaldo(saldo);
        setValue(addCommas(valor));
        //setValue(value);
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        datos[cell.row.index][cell.column.id] = valor;
        
        //setDatos([...datos]); //re-render with new data
      };

      const onBlur = () => {
        //if (nombreError==='') {
        datos[cell.row.index]['Saldo'] = saldo;
        setDatos([...datos]); //re-render with new data
        //}
        //setNombreError('');
      }
      
      //If the initialValue is changed external, sync it up with our state
      useEffect(() => {
          //console.log(datos);
          let acumulartotal = 0
          datos.map((row) => {
              acumulartotal = acumulartotal + Math.round(row.montoMovimiento);
          })
          //console.log("Total " + acumulartotal);
          setTotal(addCommas(acumulartotal));
      }, [value]);

      // If the initialValue is changed external, sync it up with our state
      //React.useEffect(() => { setValue(initialValue)}, [initialValue])

      return (
        <Input  type="text" style={{ textAlign: "center" }} sx={{ color: 'success.main', fontWeight: 'bold', textAlign: 'center'}}  disabled={disabled} 
        value={value} onChange={handleChange} onBlur={onBlur} >
        </Input>
        )
    }

    const MostrarComboTipoMov = (datosTipomov) => {
      const [dropdownOpenTM, setOpenTM] = useState(false);
      const toggleTM = () => setOpenTM(!dropdownOpenTM);
      const [tipomovtext, settipomovtext] = useState("Contado");

      const handleClick = (e) => {
          setMensajeError("");
          settipomovtext(e.target.innerText);
          //console.log(e.target.value)
          var wvalor =  parseInt(e.target.value);
          if (wvalor) {
            settipomov(wvalor);
            //ObtenerDatosAnioLectivo();
          }  
          //loadAsignaturas();
      } 
      //console.log(datosTipomov);
      return (
          <ButtonDropdown isOpen={dropdownOpenTM} toggle={toggleTM} className="my-dropdown" >
              <DropdownToggle caret color="info" >
                  {tipomovtext}
              </DropdownToggle>          
              <DropdownMenu >
                  {datosTipomov.map(item => (
                      <DropdownItem 
                            key={item.Pk_Tipomovimiento}
                            value={item.Pk_Tipomovimiento}
                            onClick={handleClick}
                            >
                          {item.Descripcion}
                      </DropdownItem>
                  ))}
              </DropdownMenu>
          </ButtonDropdown>
      )
    }

    const MostrarComboTipocomprobante = (datosTipocomprobante) => {
      
      const [dropdownOpenTipocomprobante, setOpenTipocomprobante] = useState(false);
      const toggleTipocomprobante = () => setOpenTipocomprobante(!dropdownOpenTipocomprobante);
  
      const handleClick = (e) => {
        setMensajeError("");
        setTipocomprobantetext(e.target.innerText);
        //console.log(e.target.value)
        var wvalor =  parseInt(e.target.value);
        if (wvalor) {
          setTipocomprobante(wvalor);
              //ObtenerDatosAnioLectivo();
        }  
        //loadAsignaturas();
      } 
      return (
        <ButtonDropdown isOpen={dropdownOpenTipocomprobante} toggle={toggleTipocomprobante} className="my-dropdown" >
            <DropdownToggle caret color="success">
              {Tipocomprobantetext}
            </DropdownToggle>          
            <DropdownMenu >
                    {datosTipocomprobante.map(item => (
                        <DropdownItem 
                            key={item.Pk_TipoFac}
                            value={item.Pk_TipoFac}
                            onClick={handleClick}>
                          {item.Descripcion}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </ButtonDropdown>
      )
    }

    var arrayColumnas =  [
      {
        accessorKey: 'Ctacte.Pk_CtaCte',
        header: 'id_cc',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
      },
      {
        header: 'Tipo de CC',
        accessorKey: 'Ctacte.Cuenta.Descripcion',
        //columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
        enableEditing: false, //disable editing on this column
        size: 200,
        //Filter: SelectColumnFilter,
        //filter: 'equals'
        //disableFilters: true
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              {MostrarComboTipoMov(datosTipomov)}
            </Col>
            <Stack direction={{ xs: 'column', sm: 'row' }} 
            sx={{ fontWeight: 'bold', fontSize: 14, color: 'success.dark',}}>
              
              {MostrarComboTipocomprobante(datosTipocomprobante)}
            </Stack>
          </FormGroup>
        ),
      },
      {
        header: 'Arancel',
        accessorKey: 'Ctacte.Aranceles.Descripcion',
        accessorFn: (row) => row.Ctacte.Aranceles?.Descripcion, //Muestra solo si tiene el dato
        enableEditing: false, //disable editing on this column
      },
      {
        accessorKey: 'Ctacte.Pensumcarrera.Carrera.Siglas',
        accessorFn: (row) => `${row.Ctacte.Pensumcarrera.Carrera.Siglas} ${row.Ctacte.Pensumcarrera.Aniopensum}`, //accessorFn used to join multiple data into a single cell
        header: 'Carrera',
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals', // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
        //disableFilters: true
        size: 110,
      },
      {
        header: 'Fecha CC',
        accessorKey: 'Ctacte.FechaDate',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
        //Filter: SelectColumnFilter,
        //filter: 'equals'
      },
      {
        header: 'id_det_cc',
        accessorKey: 'Pk_DetalleCtaCte',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
      },
      {
        header: 'Item',
        accessorKey: 'Descripcion',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
        size:110,
      },
      {
        header: 'Vencimiento',
        accessorKey: 'VencimientoDate',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false, //disable editing on this column
        size:120,
      },
      {
        header: 'Debe',
        accessorKey: 'MontoDebe',
        enableEditing: false, //disable editing on this column
        
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:100,
        //enableClickToCopy: true,
        //aggregationFn: 'mean',
        //required to render an aggregated cell, show the average salary in the group
        // AggregatedCell: ({ cell, table }) => (
        //   <>
        //     Average by{' '}
        //     {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
        //     <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
        //       {cell.getValue()?.toLocaleString?.('en-US', {
        //         style: 'currency',
        //         currency: 'USD',
        //         minimumFractionDigits: 0,
        //         maximumFractionDigits: 0,
        //       })}
        //     </Box>
        //   </>
        // ),
        muiTableHeadCellProps: ({ column }) => ({
          align: 'left',
          sx: {
            color: column.getIsSorted() ? 'red' : 'black',
          },
        }),
        //or in the component override callbacks
        Header: ({ column }) => <div>{column.columnDef.header}</div>,
        Cell: ({ cell, column }) => (
          <Box
            sx={{ fontWeight: 'bold',}}>
            {cell.getValue()?.toLocaleString?.('es-PY', {
                 style: 'currency',
                 currency: 'PYG',
                 currencyDisplay: "symbol",
                 minimumFractionDigits: 0,
                 maximumFractionDigits: 0,
               }).replace("Gs.", "")}
          </Box>
        ),
      },
      {
        header: 'Haber',
        accessorKey: 'MontoHaber',
        enableEditing: false, //disable editing on this column
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:100,
        Cell: ({ cell, column }) => (
          <Box
            sx={{ fontWeight: 'bold',}}>
            {cell.getValue()?.toLocaleString?.('es-PY', {
                 style: 'currency',
                 currency: 'PYG',
                 currencyDisplay: "symbol",
                 minimumFractionDigits: 0,
                 maximumFractionDigits: 0,
               }).replace("Gs.", "")}
          </Box>
        ),
        Footer: () => (
          <FormGroup> 
            <Col style={{paddingBottom: '4px' }}>
              <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold'}} disabled 
                value="Sub total">
              </Input>  
            </Col>
            <Col style={{paddingBottom: '4px' }}>
              <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold'}} disabled 
                value="Recargo">
              </Input>  
            </Col>
            <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold', color: 'green'}} disabled 
              value="Total">
            </Input>  
          </FormGroup>
        ),
      },
      {
        header: 'Movimiento',
        accessorKey: 'montoMovimiento',
        id: 'montoMovimiento',
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:120,
        enableEditing: false, //disable editing on this column
        Cell: EditableCell2,
        // Footer: () => (
        //   <Stack direction={{ xs: 'column', sm: 'row' }} 
        //   sx={{ fontWeight: 'bold', fontSize: 16, color: 'success.dark',}}>
        //     Total: <Box >{total}</Box>
        //   </Stack>
        // ),
    
        Footer: () => (
          <FormGroup >
            <Col style={{paddingBottom: '4px' }}>
              <Input type="text" style={{ textAlign: "center", fontWeight: 'bold', }} sx={{ width: 220 }} disabled 
                value={total}>
              </Input>
            </Col>
            <Col style={{paddingBottom: '4px' }}>
              <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold'}} disabled 
                value="0">
              </Input>  
            </Col>
            <Input  type="text" style={{ textAlign: "center", fontWeight: 'bold', color: 'green'}} disabled 
              value={total}>
            </Input>  
          </FormGroup>
        ),
        
      },
      {
        header: 'Saldo',
        accessorKey: 'Saldo',
        id: 'Saldo',
        enableColumnFilter: false,
        enableEditing: false, //disable editing on this column
        //right align the header and body cells
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        size:100,
        Cell: ({ cell,column }) =>  {
          const getBackgroundColor = (level) => {
            if (cell.row.original.Saldo > 0){
              return 'error.main'
            }
            else {
              return 'success.main'
            }
     
         } 
         return ( <Box
            sx={{ color: getBackgroundColor(1), fontWeight: 'bold',}}>
            {cell.getValue()?.toLocaleString?.('es-PY', {
                 style: 'currency',
                 currency: 'PYG',
                 currencyDisplay: "symbol",
                 minimumFractionDigits: 0,
                 maximumFractionDigits: 0,
               }).replace("Gs.", "")}
          </Box>
        )},
      },
    ]

    return arrayColumnas}, [datos, datosTipomov, datosTipocomprobante, Tipocomprobantetext, total]
);


    //Controlamos si data esta cargada y si lo estÃ¡ seteamos los datos 
  useEffect(() => {
    if(data) {
      //console.log(data);
      const datosUsu = data.usuarioLogueado
      //set 
      setDatos(data.DetallectactePorPersona);
    }
  }, [data]);

  if (loading) return <Alert color="info">
        Cargando...
      </Alert>
  if (error) {
    console.log(error.message)
    return (
      <Alert color="danger">
        {error.message}
      </Alert>
    )
  }
  
  const handleSaveCell = (cell, value) => {
    console.log('cell is:', cell);
    
    console.log(cell.column.id);
    //console.log(datos);
    const {original} = cell.row;
    //console.log(original);
    let montodebe = original.MontoDebe;
    let saldo = montodebe - value;
    original.Saldo = saldo
    console.log(cell.row.index);
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    datos[cell.row.index][cell.column.id] = value;
    setDatos([...datos]); //re-render with new data

    //updateMyData(original.index, original.id, value, columns)
    //datos[cell.row.index][cell.column.id] = value;
    //send/receive api updates here
    //setDatos([...datos]); //re-render with new data
  };

 // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value, columns) => {
      // We also turn on the flag to not reset the page
      console.log("UPDATE");
      console.log(datos);
      setSkipPageReset(true)
      setDatos(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            //console.log(columns)
            //console.log(columnId)
            //  if (columnId === 'Hemisphere'){
            //   if (isNaN(value)) {
            //     return {
            //       ...old[rowIndex],
            //       [columnId]: value+"prueba",
            //     }
            //   }
            //  }
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
      )
  }

  const handleClickCell = (cell, value) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    //console.log(cell.column.id);
    console.log(value);
    
    const {original} = cell.row;
    let montodebe = original.MontoDebe;
    console.log(original.index);
    //value = montodebe
    updateMyData2(original.index, original.id, montodebe, columns)
  };

  const updateMyData2 = (rowIndex, columnId, value, columns) => {
    
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setDatos(old =>
        old.map((row, index) => {
          if (index === rowIndex) {
            //console.log(columns)
            //console.log(columnId)
            //  if (columnId === 'Hemisphere'){
            //   if (isNaN(value)) {
            //     return {
            //       ...old[rowIndex],
            //       [columnId]: value+"prueba",
            //     }
            //   }
            //  }
            return {
              ...old[rowIndex],
              [columnId]: value,
            }
          }
          return row
        })
    )
  }

  // const handleChangeCell = (cell, value) => {
  //   //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
  //   const {original} = cell.row;
  //   let montodebe = original.MontoDebe;
  //   let saldo = montodebe - value;
  //   original.Saldo = saldo
  //   datos[cell.row.index][cell.column.id] = value;
  //   updateMyData(original.index, original.id, value, columns)
    
    
  //   //send/receive api updates here
  //   //setDatos([...datos]); //re-render with new data
  // };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  // const TableContainer = ({ columns, data, updateMyData, skipPageReset, editable }) => {
  //   return (
  //     <MaterialReactTable
  //       columns={columns} 
  //       data={datos} 
  //     />
  //   );
  // };

  const textField = {
    width: 200,
    fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#504572',
  }
  const toDay= new Date().toISOString().substring(0, 10);

  async function handleSaveMensaje() {
    setshowMensaje(false);
  }

  return (
    <Container fluid >
      <Form>
        <FormGroup row>
              <Col sm={1}>
                <Input type="text" name="idpersona" id="idpersona" placeholder="Id" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={idpersona} required={true} />
              </Col>
              <Col sm={3}>
                <Input type="text" name="nombrepersona" id="nombrepersona" placeholder="Nombre y apellido" disabled={true} onChange={(event)=>this.inputChangedHandler(event)} value={nombreyapellido} required={true} />
              </Col>
              <Col>
                <Button color="primary" onClick={() => setshowMensaje(true)} disabled={disablebtnPersona}><Icon.Search /></Button>
              </Col>
              <Col  sm={6}>
                {ControlledSwitches()}
              </Col>
              
          </FormGroup>
          <FormGroup row>
            <Col sm={1}>
              {MostrarComboSaldos()}
            </Col>
            <Col sm={3}>
              {MostrarComboInsti()}
            </Col>
            <Col sm={2}>
              {MostrarComboSede()}
            </Col>
            <Col sm={1}>
              {MostrarComboIE()}
            </Col>
            <Col sm={2}>
              {MostrarComboTipopago()}
            </Col>
            
          </FormGroup>
        </Form>
        <ThemeProvider theme={createTheme(theme, esES)}>
          <MaterialReactTable
              columns={columns} 
              data={datos} 
              muiTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              //updateMyData={updateMyData} 
              enableGrouping
              enableEditing
              enableColumnDragging={false}
              enableStickyHeader
              enableStickyFooter
              editingMode="table"
              enablePagination={false}
              enablePinning //Permite anclar una columna
              initialState={{
                density: 'compact',
                expanded: true, //expand all groups by default
                grouping: ['Ctacte.Cuenta.Descripcion', 'Ctacte.Aranceles.Descripcion'], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 20 },
                sorting: [{ id: 'Ctacte.Pk_CtaCte', desc: false }], //sort by state by default
                columnVisibility: { 'Ctacte.Pk_CtaCte': false, 'Pk_DetalleCtaCte': false, 'Ctacte.FechaDate': false },
              }}
              renderTopToolbarCustomActions={({ table }) => {
                
                const handleChange = (event) => {
                  const valor = Math.round(removeNonNumeric(event.target.value));
                  setValueComp(addCommas(valor));
                };
                
                return (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>

                    <TextField
                      id="fecha"
                      label="Fecha"
                      type="date"
                      defaultValue={toDay}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}  />

                      <TextField id="nroComprobante" name="nroComprobante" sx={{ width: 200 }} placeholder="Comp. NÂ°" type="text" value={valueComp} onChange={handleChange}/>
                  </div>
                );
              }}
              // renderBottomToolbarCustomActions={({ table }) => {
              //   const handleDeactivate = () => {
              //     table.getSelectedRowModel().flatRows.map((row) => {
              //       alert('deactivating ' + row.original.firstName);
              //     });
              //   };
          
              //   const handleActivate = () => {
              //     table.getSelectedRowModel().flatRows.map((row) => {
              //       alert('activating ' + row.original.firstName);
              //     });
              //   };
          
              //   const handleContact = () => {
              //     table.getSelectedRowModel().flatRows.map((row) => {
              //       alert('contact ' + row.original.firstName);
              //     });
              //   };
          
              //   return (
              //     <div style={{ display: 'flex', gap: '0.5rem' }}>
              //       <Button
              //         color="error"
              //         disabled={table.getSelectedRowModel().flatRows.length === 0}
              //         onClick={handleDeactivate}
              //         variant="contained"
              //       >
              //         Deactivate
              //       </Button>
              //       <Button
              //         color="success"
              //         disabled={table.getSelectedRowModel().flatRows.length === 0}
              //         onClick={handleActivate}
              //         variant="contained"
              //       >
              //         Activate
              //       </Button>
              //       <Button
              //         color="info"
              //         disabled={table.getSelectedRowModel().flatRows.length === 0}
              //         onClick={handleContact}
              //         variant="contained"
              //       >
              //         Contact
              //       </Button>
                    
              //     </div>
              //   );
              // }}
              localization={MRT_Localization_ES}
          />
        </ThemeProvider>

        <Modal autoFocus = {false} show={showMensaje} onHide={handleCloseMensaje} size="lg">
            <Modal.Header>
                <Modal.Title>Busqueda de persona</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <BuscarPersonaPage setshowMensaje = {setshowMensaje} setidpersona = {setidpersona} setnombreyapellido = {setnombreyapellido} />
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={handleSaveMensaje}>
                  Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

    </Container>
    )
};

export default Example;